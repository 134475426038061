import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { withRouter } from "react-router-dom";


//import BlogTileData from "../../data/blogTileData.json";

const styles = (theme) => ({
  tileGrid: {
    display: "flex",
    margin: "auto",
    maxWidth: "17rem",
    height: "20rem",
    backgroundColor: "#fefbfb",
    borderRadius: "1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.4em",
    boxShadow: "5px 5px 19px 0px rgba(255, 230, 230)",
    cursor: "pointer",
    "&:hover": {
      marginTop: "-0.3rem",
      marginLeft: "-0.1rem",
    },
  },
  imageStyle: {
    display: "flex",
    justifyContent: "center",
    objectFit: "contain",
    height: "50%",
    width: "100%",
  },
  tileTitleStyle: {
    fontSize: 20,
    padding: "0.5em 0 0.5rem 0",
  },
  readTextGrid: {
    display: "flex",
    justifyContent: "center",
  },
  subTextStyle: {
    fontSize: 12,
    color: "grey",
  },
  linkTextStyle: {
    fontSize: 16,
    color: "#fa1414",
    paddingTop: "1rem",
  },
});

export class BlogTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
      blogData: props.blogData,
    };
  }
  handleTileClick = (blogData) => {
    this.props.history.push({
      pathname: `/blog/${blogData.Name}`,
      state: { blog: blogData },
    });
  };
  render() {
    const { blogData, classes } = this.props;
    const baseUrl = "https://localhost:44361";


    return (
      <div className="tileWrapper">
        {blogData &&
          blogData.map((item, index) => {
            const imageUrl = `${baseUrl}${item.ThumbnailUrl}`;
            return (
              <Grid
                key={item.Id}
                className={classes.tileGrid}
                onClick={() => this.handleTileClick(item)}
              >
                <img src={imageUrl} className={classes.imageStyle} />
                <Typography className={classes.tileTitleStyle}>
                  {item.Title}
                </Typography>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography className={classes.subTextStyle}>
                      Author: {item.Author}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.readTextGrid}>
                    <Typography className={classes.subTextStyle}>
                      Tags : {item.Tags}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.subTextStyle}>
                      Published: {item.PublishDate}
                    </Typography>
                  </Grid>
                </Grid>
                <span className={classes.linkTextStyle}>Read more</span>
              </Grid>
            );
          })}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(BlogTile));
