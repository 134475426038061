import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
// import IASLogo from '../../assets/images/logo.png'
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IASLogo from "../assets/images/logo_ias.png";
import Typography from "@material-ui/core/Typography";
import Home from "@material-ui/icons/Home";

const styles = (theme) => ({
  notFoundbodyContainer: {
    maxWidth: "100%",
    background: "#fff",
    // height: 'auto',
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "600px",
      alignItems: "flex-start",
      paddingTop: "2em",
    },
  },
  iasLogo: {
    width: 150,  
    height: 150,
    padding: 10,
  },
  notFoundTitle: {
    fontfamily: "AvenirLTStd-Roman",
    fontSize: "2rem",
    fontWeight: 300,
  },
  notFoundDesc: {
    fontSize: "1rem",
    fontWeight: 300,
    margin: "1em auto",
    width: "25%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  redirectHomeLink: {
    padding: 15,
    borderRadius: "50%",
    color: "#fff",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#1976d2",
  },
  homeIcon: {
    position: "relative",
    top: 7,
  },
});

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
    };
  }

  render() {
    const { classes } = this.props;
    // const { subMenuOpen } = this.state
    return (
      <div
        className={[
          "container contactus-container",
          classes.notFoundbodyContainer,
        ].join(" ")}
        style={{ height: window.screen && window.screen.height }}
      >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <img alt="ias-logo" src={IASLogo} className={classes.iasLogo} />
            <Typography variant="h3" className={classes.notFoundTitle}>
              Page Not Found
            </Typography>
            <Typography variant="body2" className={classes.notFoundDesc}>
              The Page you requested is not available.
            </Typography>
            <NavLink to={"/"} className={classes.redirectHomeLink}>
              <Home className={classes.homeIcon} />
            </NavLink>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
