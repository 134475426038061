import React, { Component } from "react";
import Helvetic from "../../assets/images/company-logo/Helvetic.png";
import IFLogo from "../../assets/images/company-logo/IFLogo.png";
import People from "../../assets/images/company-logo/People.png";
import Tyrolean from "../../assets/images/company-logo/Tyrolean.png";
import Zimex from "../../assets/images/company-logo/Zimex.png";


export class LogoCarousel extends Component {
  render() {
    return (
      <div className="logos">
        <div className="logos-slide">
          <img src={Helvetic} />
          <img src={IFLogo} />
          <img src={People} />
          <img src={Tyrolean} />
          <img src={Zimex} />
          <img src={Helvetic} />
          <img src={IFLogo} />
          <img src={People} />
          <img src={Tyrolean} />
          <img src={Zimex} />
        </div>
      </div>
    );
  }
}

export default LogoCarousel;
