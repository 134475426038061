import React from "react";
import Layout from "../layouts/index";
import { Helmet } from "react-helmet-async";

const SupportPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>REDiFly Support</title>
        <meta
          name="description"
          contents="Please contact REDiFly support team for any support related to REDiFly products."
        />
        <link rel="canonical" href="/support" />
      </Helmet>
      <article className="entry">
        <div className="container">
          <div className="entry-inner">
            <div className="entry-content">
              <div className="container-sm">
                <header className="entry-header">
                  <h1 className="entry-title">Support</h1>
                </header>

                <div className="entry-body">
                  <p>Add your support information here.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default SupportPage;
