import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  productMenuContainer: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'flex-end',
    justifyContent: "center",
    marginTop: "1em",
  },
  productItemBlock: {
    fontSize: "1rem",
    minHeight: "2em",
    fontFamily: "AvenirLTStd-Roman",
    letterSpacing: "0.21px",
    color: "#707070",
    width: "75%",
    margin: "10px 0",
  },
  productItemSubBlock: {
    fontSize: "1rem",
    minHeight: "2em",
    fontFamily: "AvenirLTStd-Roman",
    letterSpacing: "0.21px",
    color: "#707070",
    width: "80%",
    marginTop: "10px",
    marginLeft: "0px",
  },
});

class EtlProductMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, subHeadersData, subHeaders } = this.props;
    return (
      <div
        id="etlMenu"
        className="product-menu-section"
        style={{ display: "none" }}
      >
        <Grid container className={classes.productMenuContainer}>
          {subHeaders.map((item, index) => {
            return (
              <Grid
                item
                lg={12 / subHeaders.length - 2}
                md={12 / subHeaders.length - 2}
                sm={12 / subHeaders.length - 2}
                style={{ position: "relative", left: index === 0 ? "2%" : 0 }}
              >
                <NavLink
                  className={classes.productItemBlock}
                  style={{ fontSize: "1.4rem", textTransform: "uppercase" }}
                  to={item.href}
                >
                    {item.name}
                    <img
                      src={item.arrowIcon}
                      style={{
                        marginBottom:"8px",
                        marginLeft:"10px"
                      }}
                    />
                </NavLink>
                <Typography>Modules</Typography>
                {subHeadersData.map((product, index) => {
                  if (item.id === product.category) {
                    return (
                      <div className={classes.productItemSubBlock}>
                        <NavLink
                          to={product.href}
                          className="product-link-lists"
                        >
                          <p>
                            {product.text}{" "}
                            <img
                              src={product.arrowIcon}
                              alt={`${product.id}-arrow`}
                            />
                          </p>
                          <span>{product.desc}</span>
                        </NavLink>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(EtlProductMenus);
