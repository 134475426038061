import ScrollReveal from 'scrollreveal';

export default function initialize() {
  const doc = document.documentElement
  doc.classList.remove('no-js')
  doc.classList.add('js')
}

export function setupHeaderBg() {
  const doc = document.documentElement
  const win = window
  const headerBg = document.querySelector('.site-header-large-bg span')

  function setHeaderBgHeight() {
    const bodyHeight = doc.getElementsByTagName('body')[0].clientHeight
    headerBg.style.height = `${bodyHeight}px`
  }

  setHeaderBgHeight()
  win.addEventListener('load', setHeaderBgHeight)
  win.addEventListener('resize', setHeaderBgHeight)
}

export function setupScrollReveal() {
  if (document.body.classList.contains('has-animations')) {
    const sr = window.sr = ScrollReveal()

    sr.reveal('.hero-title, .hero-paragraph, .hero-form', {
      duration: 1000,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'top',
      interval: 150
    })

    sr.reveal('.hero-ball', {
      delay: 1000,
      duration: 1400,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'bottom',
      interval: 200
    })

    sr.reveal('.hero-illustration-browser, .hero-squares', {
      delay: 400,
      duration: 600,
      distance: '40px',
      easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
      origin: 'right',
      interval: 150
    })
  }
}

export function loadHeaderStickymenus () {
  (function(w,d,undefined){
    let el_html = d.documentElement,
    el_body = d.getElementsByTagName('body')[0],
    header = d.getElementById('header'),
    menuIsStuck = function() {
      let wScrollTop	= w.pageYOffset || el_body.scrollTop,
        regexp		= /(nav\-is\-stuck)/i,
        classFound	= el_html.className.match( regexp ),
        navHeight	= header.offsetHeight,
        bodyRect	= el_body.getBoundingClientRect(),
        scrollValue	= 100;
 
      if ( wScrollTop > scrollValue && !classFound ) {
        el_html.className = el_html.className + ' nav-is-stuck';
        el_body.style.paddingTop = navHeight + 'px';
      }
 
      if ( wScrollTop < 2 && classFound ) {
        el_html.className = el_html.className.replace( regexp, '' );
        el_body.style.paddingTop = '0';
      }
 
    },
    onScrolling = function() {
      menuIsStuck();
    };
 
  w.addEventListener('scroll', function(){
    w.requestAnimationFrame( onScrolling );
  });
 
 }(window, document));
}