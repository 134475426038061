import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppLogo from "../assets/images/REDiFly_Events_Logo.png";
import AppStore from "../assets/images/download-on_App_Store_logo.png";
import PlayStore from "../assets/images/get-it-on-google-play.png";
import APKDownload from "../assets/images/apk_download.jpg";
import apk from "../assets/apk/redifly-events-release.apk";


import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";

const styles = (theme) => ({
    appTitle: {
        fontfamily: "AvenirLTStd-Roman",
        fontSize: "1.6rem",
        textAlign: "center",
        fontWeight: 300,
        marginBottom: "20px",
        "@media (max-width: 525px)": {
            color: "white",
            fontSize: "1rem",
            marginBottom: "10px",
        }
    },
    body: {
        fontSize: "0.7rem",
        textAlign: "center",
        marginBottom: "20px",
        "@media (max-width: 525px)": {
            color: "white",
            fontSize: "0.6rem",
            marginBottom: "10px",
        }
    },
    bannerTitle: {
        marginTop: "30px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 500,
        color: "white"
    },
    bannerbody: {
        fontSize: "14px",
        textAlign: "center",
        fontWeight: 500,
        color: "white"
    },
    storeImg: {
        marginRight: "auto",
        marginLeft: "auto",
        width: 200,
        height: 80,
        display: "block",
        objectFit: "contain"
    },
    container: {
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 60px",
    },
    bannerBackground: {
        backgroundImage: "linear-gradient(rgb(30, 148, 250), rgb(45, 222, 255))",
        minHeight: "300px",
        width: "100vw",
        position: "absolute",
        zIndex: -1,
        "@media (max-width: 525px)": {
            minHeight: "100vh",
        },

    },
    webLinkWraper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '10px'

    },

    webLink: {
        display: "block",
        textAlign: "center",
        textDecoration: "underline",
        fontSize: "20px",
        fontWeight: 500,
        "@media (max-width: 525px)": {
            color: "white",
        }

    },
    webLinkTitle: {
        fontSize: "20px",
        marginBottom: "0",
        fontWeight: 500,
        "@media (max-width: 525px)": {
            color: "white",
        }
    },
    card: {
        maxWidth: 400,
        margin: "75px 0",
        padding: "40px",
        borderRadius: "5px",
        backgroundColor: "white",
        boxShadow: "0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16)",
        "@media (max-width: 525px)": {
            backgroundColor: "unset",
            boxShadow: "unset",
            padding: "20px",
            maxWidth: "unset",
        },
    },
    appLogo: {
        boxShadow: "0 0 14px 1px rgba(0, 0, 0, 0.2)",
        borderRadius: "15px",
        marginRight: "auto",
        marginLeft: "auto",
        display: "block",
        backgroundColor: "white",
        marginBottom: "20px",
        width: 120,
        height: 120,
    },
});

class ConfAppInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenuOpen: false,
        };
    }
    handleDownloadClick() {
        const apkFile = apk;
        const anchor = document.createElement("a");
        anchor.href = "https://rediflywebhosting.blob.core.windows.net/rediflyevents/redifly-events-release.apk";
        anchor.download = "REDifly Events";
        anchor.click();
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.bannerBackground}>
                    <Typography variant="h3" className={classes.bannerTitle}>
                        REDiFly Events
                    </Typography>
                    <Typography variant="body2" className={classes.bannerbody}>
                        Integrated Aviation Systems AG
                    </Typography>
                </div>

                <div className={classes.container}>
                    <div className={classes.card}>
                        <img alt="app-logo" src={AppLogo} className={classes.appLogo} />
                        <Typography variant="h3" className={classes.appTitle}>
                            REDiFly Events
                        </Typography>
                        <Typography variant="body2" className={classes.body}>
                            REDiFly Events is designed to enhance your experience at industry
                            events, conferences, and meetups by providing essential tools
                            right on your mobile device.
                        </Typography>
                        <Link
                            href="https://apps.apple.com/us/app/redifly-events/id6636482620?uo=4"
                            target="_blank"
                        >
                            <img alt="app-logo" src={AppStore} className={classes.storeImg} />
                        </Link>
                        {/* <Link href="https://play.google.com/store/apps/details?id=com.intavsys.rediflyconf6" target="_blank">
                            <img alt="app-logo" src={PlayStore} className={classes.storeImg} />
                        </Link> */}
                        <a href="https://rediflywebhosting.blob.core.windows.net/rediflyevents/redifly-events-release.apk" >
                            <img
                                alt="app-logo"
                                src={APKDownload}
                                className={classes.storeImg}
                            />
                        </a>
                        <div className={classes.webLinkWraper}>
                            <p className={classes.webLinkTitle}>Web Link:</p>
                            <Link
                                href="https://conf.redifly.com/"
                                className={classes.webLink}
                                target="_blank"
                            >
                                conf.redifly.com
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(ConfAppInfoPage);
