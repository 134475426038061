import React, { Component } from "react";
import ACKwik from "../../assets/images/integration-logo/AC-U-KWIK.png";
import Amos from "../../assets/images/integration-logo/AMOS.png";
import Avinode from "../../assets/images/integration-logo/Avinodegroup.png";
import Capzlog from "../../assets/images/integration-logo/capzlog aero.png";
import CrewDox from "../../assets/images/integration-logo/crewdox.png";
import EuroControl from "../../assets/images/integration-logo/EuroControl.png";
import ForeFlight from "../../assets/images/integration-logo/foreflight.png";
import IFS from "../../assets/images/integration-logo/IFS.png";
import MsAd from "../../assets/images/integration-logo/AzureAD.png";
import MyId from "../../assets/images/integration-logo/MyId.png";
import Pnrgo from "../../assets/images/integration-logo/pnrgo.png";
import Portside from "../../assets/images/integration-logo/Portside.png";
import PowerBI from "../../assets/images/integration-logo/PowerBI.png";
import PPS from "../../assets/images/integration-logo/PPS.png";
import REDiFly from "../../assets/images/integration-logo/REDiFly.png";
import Sita from "../../assets/images/integration-logo/Sita.png";
import Skyplan from "../../assets/images/integration-logo/Skyplan.png";
import WinAir from "../../assets/images/integration-logo/WinAir.png";

export class IntegrationCarousel extends Component {
  render() {
    return (
      <div className="int-logos">
        <div className="int-logos-slide">
          <img src={ACKwik} />
          <img src={Amos} />
          <img src={Avinode} />
          <img src={Capzlog} />
          <img src={CrewDox} />
          <img src={EuroControl} />
          <img src={MyId} />
          <img src={Pnrgo} />
          <img src={Portside} />
          <img src={PowerBI} />
          <img src={PPS} />
          <img src={REDiFly} />
          <img src={ForeFlight} />
          <img src={IFS} />
          <img src={MsAd} />
          <img src={Sita} />
          <img src={Skyplan} />
          <img src={WinAir} />
        </div>
      </div>
    );
  }
}

export default IntegrationCarousel;
