import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import { setupHeaderBg } from '../../assets/js/main';
import IASLogo from "../../assets/images/logo_ias.png";
import CBLLogo from "../../assets/images/CBLLogo.png";
import ISOLogo from "../../assets/images/ISO Post.png";
import EbaaLogo from "../../assets/images/ebaalogo.png";
import Grid from "@material-ui/core/Grid";
// import NavLinks from './NavLinks';
// import IASLogo from '../../assets/images/logo.png'
import { NavLink } from "react-router-dom";
// import Collapse from '@material-ui/core/Collapse'
import { withStyles } from "@material-ui/core/styles";
// import classes from '*.module.css';
import Typography from "@material-ui/core/Typography";
// import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  footerContainer: {
    background: "#ECF4FA",
    padding: "2em 0 2em 0",
  },
  iasLogoContainer: {
    display: "flex",
    // justifyContent: 'center',
    alignItems: "center",
    flexDirection: "column",
    // paddingTop: '2em',
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "center",
    },
  },
  mainCBLLogoGrid: {
    paddingTop: "2.4em",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "1em",
    },
  },
  iasIconMainGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iasLogo: {
    width: 145,
    height: 140,
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 77,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 100,
      height: 97,
    },
  },
  instagramLogo: {
    width: "100%",
    height: "100%",
  },
  instagramLogoGrid: {
    maringTop: "-5px",
  },
  cblLogo: {
    width: 135,
    height: 90,
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: 70,
      height: 45,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 100,
      height: 65,
    },
  },
  ebaaLogo: {
    width: 500,
    height: 90,
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: 200,
      height: 60,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      height: 70,
    },
  },
  isoLogo: {
    width: 70,
    height: 75,
    padding: 0,
    marginLeft: 50,
    marginTop: -25,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: 50,
      height: 53,
      marginTop: "0em",
    },
  },
  leftLinkContentGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  firstLinksMainGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "2rem",
  },
  mainLinksHeading: {
    color: "black",
    fontWeight: 700,
    paddingBottom: "1em",
    textDecoration: "none",
  },
  subLinksStyle: {
    textDecoration: "none",
    color: "black",
    fontSize: 16,
  },
  secondLinksMainGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footerDescGrid: {
    display: "flex",
    [theme.breakpoints.between("xs")]: {
      justifyContent: "left",
    },
    [theme.breakpoints.between("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.between("lg")]: {
      justifyContent: "left",
    },
  },
  isoMainGrid: {
    display: "flex",
    justifyContent: "start",
  },
  footerDesc: {
    // fontSize: '0.9rem',
    fontSize: 16,
    fontFamily: "S-Core-CoreSansA35Light",
    // paddingLeft: "1em",
    paddingRight: "1em",
    paddingBottom: "2em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  isoMainHeading: {
    whiteSpace: "nowrap",
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 22,
    },
  },
  isoLogoGrid: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: 2,
    },
  },
  contactButton: {
    // width: 200,
    // marginTop: '1em',
    // textAlign: 'center',
    borderRadius: "8px",
    padding: 5,
    margin: "30px 0 0 0",
    height: 40,
    [theme.breakpoints.down("xs")]: {
      height: 30,
    },
  },
  contactButtonLink: {
    textDecoration: "none",
    color: "#cc0000",
    // fontSize: 16,
    fontSize: 16,
    padding: "5px 10px !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  contactDetailsDesc: {
    display: "flex",
    flexDirection: "column",
    // fontSize: '0.9rem',
    fontSize: 16,
    paddingtop: "1em",
    fontFamily: "S-Core-CoreSansA35Light",
    alignItems: "baseline",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.7rem",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  privacyTerms: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  socialMediaiconStyle: {
    fontSize: "2em",
    padding: "0 0px",
    position: "relative",
    bottom: 6,
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: 0,
    },
    [theme.breakpoints.between("md", "lg")]: {
      // bottom: 10,
    },
    [theme.breakpoints.up("lg")]: {
      // bottom: 10,
    },
  },
  instagramLogoGrid: {
    marginTop: "-0.2rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",

    },
  },
  isoiconStyle: {
    fontSize: "2em",
    padding: "0 0px",
    position: "relative",
    bottom: 10,
    left: 0,
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: 0,
    },
    [theme.breakpoints.between("md", "lg")]: {
      bottom: 10,
    },
    [theme.breakpoints.up("lg")]: {
      bottom: 10,
    },
  },
  contactMailPhone: {
    display: "flex",
    flexDirection: "column",
  },
  contactGridContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      display: "flex",
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      display: "initial",
      justifyContent: "center",
    },
  },
  footerContentGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contactDetailsGridBlock: {
    display: "flex",
    alignItems: "left",
  },
  contactDetailsGridContainer: {
    display: "flex",
    padding: "2em",
    [theme.breakpoints.between("sm", "md")]: {
      alignItems: "center",
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: '#ff00003b',
      textAlign: "left",
    },
    [theme.breakpoints.up("lg")]: {
      // background: 'green'
    },
  },
  socialMediaLinksBlock: {
    marginTop: "2em",
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  socialMediaLinksGrid: {
    display: "flex",
    justifyContent: "center",
  },
  socialMainHeading: {
    display: "flex",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  logoDesc: {
    // fontSize: 14,
    fontSize: 12,
    // fontSize: '0.9rem',
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
      padding: "0 1em",
      textAlign: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "80%",
      textAlign: "center",
    },
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    // const {  } = this.state
    return (
      <div>
        <Grid container className={classes.footerContainer}>
          {/* //data-aos="fade-up" */}
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.iasLogoContainer}
          >
            <Grid container>
              <Grid item xs={6} sm={6} lg={12}>
                <Grid className={classes.iasIconMainGrid}>
                  <img
                    alt="ias-logo"
                    src={IASLogo}
                    className={classes.iasLogo}
                  />
                  <p className={classes.logoDesc}>
                    © 2023 INTEGRATED AVIATION SYSTEMS AG
                    <div className={classes.privacyTerms}>
                      <a href="https://www.termsfeed.com/privacy-policy/33c2e5b507ade1581ee04d399cb61802" target="_blank" style={{ color: '#0178EF', textDecoration: 'none', fontSize: "14px", textAlign: "center" }}>Privacy</a>
                      <a href="https://www.termsfeed.com/live/5e90d0a8-8e87-4b28-af0a-50306f76d3d1" target="_blank" style={{ color: '#0178EF', textDecoration: 'none', fontSize: "14px", textAlign: "center" }}> | Terms</a>
                      {/* <NavLink
                        to="https://www.termsfeed.com/privacy-policy/33c2e5b507ade1581ee04d399cb61802"
                        target="_blank"
                        style={{
                          color: "#0178EF",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Privacy{" "}
                      </NavLink>
                      <NavLink
                        to="https://www.termsfeed.com/live/5e90d0a8-8e87-4b28-af0a-50306f76d3d1"
                        target="_blank"
                        style={{
                          color: "#0178EF",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        | Terms
                      </NavLink> */}
                    </div>
                  </p>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                lg={12}
                className={classes.mainCBLLogoGrid}
              >
                <Grid className={classes.iasIconMainGrid}>
                  <img
                    alt="ias-logo"
                    src={CBLLogo}
                    className={classes.cblLogo}
                  />
                  <p className={classes.logoDesc}>© 2023 CBL Electronics</p>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                lg={12}
                className={classes.mainCBLLogoGrid}
              >
                <Grid className={classes.iasIconMainGrid}>
                  <img
                    alt="ias-logo"
                    src={EbaaLogo}
                    className={classes.ebaaLogo}
                  />
                  <p className={classes.logoDesc}>
                    © The European Business Aviation Association
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={6}
            className={classes.leftLinkContentGrid}
          >
            <Grid className={classes.firstLinksMainGrid}>
              <Typography className={classes.mainLinksHeading}>ETL</Typography>
              <NavLink to="/product/pilot" className={classes.subLinksStyle}>
                Pilot
              </NavLink>
              <NavLink to="/product/tech" className={classes.subLinksStyle}>
                Tech
              </NavLink>
              <NavLink to="/product/crew" className={classes.subLinksStyle}>
                Crew
              </NavLink>
              <NavLink to="/wproduct/command" className={classes.subLinksStyle}>
                Command
              </NavLink>
              <NavLink to="/wproduct/core" className={classes.subLinksStyle}>
                Core
              </NavLink>
            </Grid>
            <Grid className={classes.secondLinksMainGrid}>
              <Typography className={classes.mainLinksHeading}>OPS</Typography>
              <NavLink to="/wopsproduct/dispatch" className={classes.subLinksStyle}>
                Dispatch
              </NavLink>
              <NavLink
                to="/wopsproduct/scheduling"
                className={classes.subLinksStyle}
              >
                Scheduling
              </NavLink>
              <NavLink to="/wopsproduct/crew" className={classes.subLinksStyle}>
                Crew
              </NavLink>
              <NavLink to="/wopsproduct/sales" className={classes.subLinksStyle}>
                Sales
              </NavLink>
              <NavLink to="/wopsproduct/training" className={classes.subLinksStyle}>
                Training
              </NavLink>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={6}
            className={classes.leftLinkContentGrid}
          >
            {/* <Grid className={classes.firstLinksMainGrid}>
              <Typography className={classes.mainLinksHeading}>Blog</Typography>
              <NavLink className={classes.subLinksStyle}>Blog 5</NavLink>
              <NavLink className={classes.subLinksStyle}>Blog 4</NavLink>
              <NavLink className={classes.subLinksStyle}>Blog 3</NavLink>
              <NavLink className={classes.subLinksStyle}>Blog 2</NavLink>
              <NavLink className={classes.subLinksStyle}>Blog 1</NavLink>
            </Grid> */}
            <Grid className={classes.firstLinksMainGrid}>
              <Typography className={classes.mainLinksHeading}>
                About Us
              </Typography>
              <NavLink to="/about#ourstory" className={classes.subLinksStyle}>
                Our Story
              </NavLink>
              <NavLink to="/about#career" className={classes.subLinksStyle}>
                Careers
              </NavLink>
              <NavLink to="/about#contact" className={classes.subLinksStyle}>
                Contact Us
              </NavLink>
            </Grid>
            <Grid className={classes.firstLinksMainGrid}>
              <Typography>
                <NavLink to="/blog" className={classes.mainLinksHeading}>
                  Blog Page
                </NavLink>
              </Typography>
            </Grid>
            {/* <Grid className={classes.firstLinksMainGrid}>
              <Typography className={classes.mainLinksHeading}>
                Customer Portal
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={classes.contactDetailsGridBlock}
          >
            <Grid container className={classes.contactDetailsGridContainer}>
              <Grid
                item
                xs={6}
                sm={6}
                lg={12}
                className={classes.footerContentGrid}
              >
                <Grid container>
                  <Grid item xs={12} className={classes.footerDescGrid}>
                    <Typography variant="body1" className={classes.footerDesc}>
                      Integrated Aviation Systems AG <br />
                      Sihleggstrasse 23 <br />
                      8832 Wollerau <br /> Switzerland
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.isoMainGrid}>
                  <Grid item xs={12} sm={5} lg={7}>
                    <Typography className={classes.isoMainHeading}>
                      ISO 27001 Certified
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    lg={5}
                    className={classes.isoLogoGrid}
                  >
                    <NavLink
                      to="https://www.tuv-nord.com/fileadmin/Content/TUV_NORD_COM/TUV_INDIA/pdf/product-information-iso-27001.pdf"
                      target="_blank"
                    >
                      <img
                        alt="ias-logo"
                        src={ISOLogo}
                        className={classes.isoLogo}
                      />
                    </NavLink>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                lg={12}
                className={classes.footerDescGrid}
              >
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.footerDescGrid}
                  >
                    <div className={classes.contactDetailsDesc}>
                      <div className={classes.contactMailPhone}>
                        <a
                          href="mailto:sales@redifly.com"
                          target="_blank"
                          style={{ color: "#0178EF", textDecoration: "none" }}
                        >
                          sales@redifly.com
                        </a>
                        <span>+41 43 555 31 65</span>
                      </div>
                      <div
                        className={["border-menu", classes.contactButton].join(
                          " "
                        )}
                      >
                        <NavLink
                          to={"/about#contact"}
                          className={classes.contactButtonLink}
                        >
                          {"Contact Us"}
                        </NavLink>
                      </div>
                      <div className={classes.socialMediaLinksBlock}>
                        {/* <NavLink to="https://www.facebook.com/" target="_blank" rel="" ><i className={["fa fa-facebook fa-2", classes.socialMediaiconStyle].join(' ')} aria-hidden="true" style={{color: '#4867AA'}} /></NavLink> */}
                        <Grid
                          container
                          spacing={3}
                          className={classes.socialMediaLinksGrid}
                        >
                          <Grid item xs={5}>
                            <Typography className={classes.socialMainHeading}>
                              Follow Us
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <a
                              href="https://www.linkedin.com/company/redifly/"
                              target="_blank"
                            >
                              <i
                                className={[
                                  "fa-brands fa-linkedin-in",
                                  classes.socialMediaiconStyle,
                                ].join(" ")}
                                aria-hidden="true"
                                style={{ color: "#2867B2" }}
                              />
                            </a>
                          </Grid>
                          <Grid item xs={2}>
                            <a
                              href="https://www.youtube.com/@redifly5063"
                              target="_blank"
                            >
                              <i
                                className={[
                                  "fa-brands fa-youtube",
                                  classes.socialMediaiconStyle,
                                ].join(" ")}
                                aria-hidden="true"
                                style={{ color: "#FF0000" }}
                              />
                            </a>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={classes.instagramLogoGrid}
                          >
                            <a
                              href="https://www.instagram.com/redifly_/?hl=en"
                              target="_blank"
                            >
                              <img
                                width="40"
                                height="40"
                                alt="insta-logo"
                                src="/images/instalogo.png"
                                className={classes.socialMediaiconStyle}
                              />
                            </a>
                          </Grid>
                        </Grid>
                        {/*<span></span><NavLink to="https://youtu.be/_0tavthjNXw" target="_blank"><i className={["fa fa-youtube fa-2", classes.socialMediaiconStyle].join(' ')} aria-hidden="true" style={{color: '#C90A07'}} /></NavLink>*/}
                        {/* <NavLink to="https://twitter.com/" target="_blank"><i className={["fa fa-twitter fa-2", classes.socialMediaiconStyle].join(' ')} aria-hidden="true" style={{color: '#2AA5DA'}} /></NavLink> */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item lg={6} md={12} sm ={12} xs={12} className={classes.contactGridContainer}>
                <div className={["border-menu", classes.contactButton].join(' ')}>
                  <NavLink to={'/about#contact'} className={classes.contactButtonLink}>{'Contact Us'}</NavLink>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
