import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
// import NavLinks from './NavLinks';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// import Layout from '../layouts/index'
import Paper from "@material-ui/core/Paper";
import ContactData from "../data/contactus.json";
// import { ReactBingmaps } from 'react-bingmaps'
import OfficeLocation from "../assets/images/img_EuropeMap.png";

const styles = (theme) => ({
  contactUsTitle: {
    fontSize: 40,
    color: "#223140",
    width: "50%",
    fontFamily: "AvenirLTStd-Roman",
    paddingBottom: "1em",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 35,
    },
  },
  contactUsTitleDesc: {
    width: "70%",
    margin: "2em 0",
    fontSize: 24,
    fontFamily: "AvenirLTStd-Book",
    color: "#223140",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {},
  },
  contactContainer: {
    padding: "2em 2em 2em 0em",
    [theme.breakpoints.down("xs")]: {
      padding: "0em",
    },
  },
  papeRootContact: {
    width: "75%",
    marginLeft: "6em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "100%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: 0,
      width: "100%",
    },
  },
  descIconContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  contactmailPaperTitle: {
    fontSize: 26, //fontSize: 30,
    fontWeight: "inherit",
    color: "#000",
    fontFamily: "AvenirLTStd-Roman",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  contactmailPaperDesc: {
    fontSize: 20, //fontSize: 23,
    fontWeight: "inherit",
    fontFamily: "AvenirLTStd-Book",
    color: "#000",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  contactmailPaperanchor: {
    fontSize: 21, // fontSize: 26,
    fontWeight: "inherit",
    fontFamily: "AvenirLTStd-Roman",
    color: "#0278EF",
    marginTop: 10,
    textDecoration: "none",
    wordBreak: "break-all",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  imageIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mapsContainer: {
    marginBottom: "1em",
  },
  locationImageStyle: {
    height: "auto",
    width: "auto",
  },
  contactEmployeeMainGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("lg")]: {},
  },
  contactSalesMainGrid: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.between("md", "lg", "xl")]: {
      flexDirection: "row",
    },
  },
  contactContentGrid: {
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "2em",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingLeft: "4em",
    },
  },
  contactUsMainGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: "5em",
    marginBottom: "1em",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: "0em",
    },
  },
  employeeImageStyle: {
    height: 120,
    width: 120,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "grey",
    borderRadius: "4rem",
    objectFit: "contain",
    backgroundColor: "#bfbfbf",
  },
  alternateEmployeeImageStyle: {
    height: 120,
    width: 120,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "grey",
    borderRadius: "4rem",
    objectFit: "contain",
    backgroundColor: "#bfbfbf",
  },
  employeeTextStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "AvenirLTStd-Roman",
    whiteSpace: "nowrap",
    fontWeight: 600,
    fontSize: 20,
    marginTop: "0.5em",
  },
  employeeTextStyle2: {
    display: "flex",
    fontFamily: "AvenirLTStd-Roman",
    justifyContent: "center",
    whiteSpace: "nowrap",
    color: "grey",
    marginTop: "0.2em",
  },
  commonButtonStyle: {
    textTransform: "inherit",
    width: 210, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  discoveryButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    // marginLeft: "1em",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    marginTop: "1.2em",
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20, // fontSize: 30,
    },
  },
  contactImgIcon: {
    width: 60,
    height: 60,
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 50,
      marginTop: 10,
    },
  },
});

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
    };
  }
  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Q/A");
    const email = "support@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

   handleCallClick = () => {
   window.open('https://calendly.com/redifly/discoverycall', '_blank');
  };

  render() {
    const { classes } = this.props;
    // const { subMenuOpen } = this.state
    return (
      // <Layout>
      <div>
        <Grid container className={classes.contactContainer} > 
        {/* //data-aos="fade-in" */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" className={classes.contactUsTitle}>
              {ContactData.contactTitle}
            </Typography>
            {/* <Typography variant="body2" className={classes.contactUsTitleDesc}>
              {ContactData.contactDesc}
            </Typography> */}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.mapsContainer}
          >
            {/* <div id="googleMap" style={{width:'auto', height:450}}></div> */}
            {/* <ReactBingmaps 
              bingmapKey = "Aiz553qO-grJ2YZQ4wIf_int2AU7Y_D4VHF57u3EF_nPT4eoTmm991Ofy5NvSpIs"
              center={[53.98502507623196, -6.3941237480078446]}
              zoom={13}
              // points={5}
            >
            </ReactBingmaps> */}
            <img src={OfficeLocation} className={classes.locationImageStyle} />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.contactEmployeeMainGrid}
          >
            <Grid container>
              <Grid className={classes.contactSalesMainGrid}>
                {ContactData &&
                  ContactData.enquiryData &&
                  ContactData.enquiryData.map((item, index) => {
                    return (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        key={index}
                        // data-aos="flip-up"
                        className={classes.contactContentGrid}
                      >
                        {/* <Paper className={classes.papeRootContact} elevation={1}>
                        <Grid container>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            className={classes.imageIconContainer}
                          >
                            <img
                              alt="ias-logo"
                              src={item.imagePath}
                              className={[
                                classes.contactImgIcon,
                                item.id === "sales" ? "sales-image-icon" : "",
                              ].join(" ")}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sm={8}
                            xs={12}
                            style={{ padding: 10 }}
                            className={classes.descIconContainer}
                          >
                            <Typography
                              variant="h6"
                              className={classes.contactmailPaperTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.contactmailPaperDesc}
                            >
                              {item.desc}
                            </Typography>
                            <a
                              href={`mailto:${item.emailAddress}?subject=${item.mailSubject}`}
                              className={classes.contactmailPaperanchor}
                            >
                              {item.emailAddress}
                            </a>
                          </Grid>
                        </Grid>
                      </Paper> */}
                        <Grid className={classes.contactUsMainGrid}>
                          <img
                            src={item.imagePath}
                            className={[
                              classes.employeeImageStyle,
                              index % 2 !== 0
                                ? classes.alternateEmployeeImageStyle
                                : "",
                            ].join(" ")}
                          />
                          <Typography
                            variant="body2"
                            className={classes.employeeTextStyle}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.employeeTextStyle2}
                          >
                            {item.designation}
                          </Typography>
                          <Button
                            key={item.id}
                            variant="contained"
                            onClick={
                              item.id === "support"
                                ? this.handleEmailClick
                                : this.handleCallClick
                            }
                            className={[
                              classes.commonButtonStyle,
                              classes.discoveryButtonStyle,
                            ].join(" ")}
                          >
                            {item.button}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      // </Layout>
    );
  }
}

export default withStyles(styles)(ContactUs);
