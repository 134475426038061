import React, { Component } from "react";
// import Grid from '@material-ui/core/Grid'
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const styles = (theme) => ({
  signInCard: {
    width: "75%",
    maxWith: 500,
    padding: "1em",
    boxShadow: "0px 2px 14px 0px rgba(153,153,153,0.3)",
    margin: "2em 0 2em 4em",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  textFieldsBlock: {
    margin: "20px 0",
  },
  signInCardTitle: {
    fontFamily: "S-Core-CoreSansA55Medium",
    color: "#223140",
    // fontSize: '1.2rem'
    fontSize: 30, // fontSize: 32,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  textLable: {
    fontFamily: "S-Core-CoreSansA55Medium",
    color: "#223140",
    // fontSize: '0.9rem'
    fontSize: 24, // fontSize: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  textFieldRoot: {
    height: 40,
  },
  signInButtonStyle: {
    background: "#B41E1C",
    fontFamily: "S-Core-CoreSansA65Bold",
    color: "#fff",
    textTransform: "capitalize",
    width: 150,
    marginLeft: 10,
    // fontSize: '0.9rem',
    fontSize: 22,
    boxShadow: "0px 10px 13px -7px rgba(180,30,28,0.1)",

    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  forgetPassword: {
    color: "#0278EF",
    fontSize: 16,
    textDecoration: "none",
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
});

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    // const {  } = this.state
    return (
      <Card className={classes.signInCard}>
        <CardContent>
          <Typography
            className={classes.signInCardTitle}
            color="textSecondary"
            gutterBottom
          >
            Sign into your account
          </Typography>
          <div
            className={[classes.textFieldsBlock, "textfield-block"].join(" ")}
          >
            <label className={classes.textLable} for="email">
              Email
            </label>
            <TextField
              id="email"
              label=""
              // style={{ margin: 8 }}
              placeholder="example@email.com"
              // helperText="Full width!"
              fullWidth
              type="password"
              margin="none"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className="signin-textfield"
            />
          </div>
          <div
            className={[classes.textFieldsBlock, "textfield-block"].join(" ")}
          >
            <label className={classes.textLable} for="password">
              Password
            </label>
            <TextField
              id="password"
              label=""
              // style={{ margin: 8 }}
              placeholder="Type Password"
              // helperText="Full width!"
              fullWidth
              type="password"
              margin="none"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className="signin-textfield"
            />
            <a href="#" className={classes.forgetPassword}>
              Forgot Password?
            </a>
          </div>
        </CardContent>
        <CardActions>
          <Button className={classes.signInButtonStyle}>Sign In</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(SignInForm);
