import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CaseStudy from "../../assets/pdfs/IFCaseStudy.pdf";
import { useHistory } from "react-router-dom";
import emailjs from "emailjs-com";

const styles = (theme) => ({
  mainHeading: {
    fontSize: 25,
  },
  closeButton: {
    marginLeft: "auto",
  },
  emailInput: {
    marginBottom: "1rem",
  },
  dialougBox: {
    paddingTop: "1rem",
  },
  submitButton: {
    background: "#B41E1C !important",
    "&:hover": {
      background: "#8c1513",
    },
  },
});

const EmailModal = ({ open, handleClose, classes, source }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const history = useHistory();

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
    setIsValidEmail(isValid);
  };

  const downloadCaseStudy = () => {
    const pdfFile = CaseStudy;
    const anchor = document.createElement("a");
    anchor.href = pdfFile;
    anchor.download = "IndustriFlyg Case Study";
    anchor.click();
  };

  const webinarPageNavigation = () => {
    history.push("/videopage");
  }

  const handleSubmit = () => {
    if (isValidEmail && email.trim() !== "") {
      console.log("Email submitted:", email);
      if(source == "Case Study") {
         sendEmail();
         downloadCaseStudy();
         handleClose();
      } else {
        sendEmail();
        webinarPageNavigation();
        handleClose();
      }
     
    }
  };
   const sendEmail = () => {
     const templateParams = {
       to_name: "Jack Clancy",
       from_name: email,
       subject: "New Visitor viewed : " + source,
       message: "The user " + email +  " has viewed : " + source,
     };

     emailjs
       .send(
         "service_m9oyp3p",
         "template_tztubkr",
         templateParams,
         "LqnA_PGmQuHqR6M01"
       )
       .then((response) => {
         console.log("Email sent successfully:", response);
       })
       .catch((error) => {
         console.error("Error sending email:", error);
       });
   };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className={classes.mainHeading}>Enter your email</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          style={{ marginLeft: "auto" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialougBox}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          style={{ marginBottom: "1rem" }}
          required
          error={!isValidEmail}
          helperText={!isValidEmail && "Enter a valid email address"}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className={classes.submitButton}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(EmailModal);
