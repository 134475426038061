import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpward from "@material-ui/icons/KeyboardArrowUp";
import Header from "../components/layout/Header";
import Main from "../components/layout/Main";
import Footer from "../components/layout/Footer";
import AOS from "aos";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    AOS.init({
      duration: 1200,
    });
  }
  componentWillReceiveProps(nextProps) {
    // console.log('difference', nextProps.children !== this.props.children)
  }

  scrollTop = (event) => {
    // console.log(event)
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    // const { history  } = this.props
    // console.log('props', this.props.children._self)
    // console.log('history', history)
    // const { subMenuOpen } = this.state
    return (
      <div className="body-wrap boxed-container">
        <Header
          props={this.props}
          location={
            this.props && this.props.children && this.props.children._self
          }
        />
        <Main>{this.props.children}</Main>
        <Footer />
        <IconButton
          onClick={this.scrollTop.bind(this)}
          id="scroll-up-ele"
          className="scroll-up-button"
        >
          <ArrowUpward className="scroll-up-icon" />
        </IconButton>
      </div>
    );
  }
}

export default Layout;
