import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  mainGrid: {
    paddingTop: "1rem",
  },
  mainLeftGrid: {
    // left: "0.7em",
  },
  mainRightGrid: {
    paddingTop: "4rem",
  },
  etlFeatureGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "1em 0em 1em 6em",
    [theme.breakpoints.down("xs")]: {
      padding: "1em 1.7em 1em 1.5em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "1em 0em 1em 1.5em",
    },
  },
  etlFeatureTitle: {
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  etlFeatureSubHeading: {
    fontFamily: "AvenirLTStd-Book",
    fontSize: 18,
    color: "black",
    paddingRight: "5em",
    paddingTop: "0.5em",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0em",
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingRight: "0em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      paddingRight: "0em",
    },
  },
  etlFeatureImage: {
    width: 50, // width: 81,
    height: 50, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  etlFeatureImageGrid: {
    display: "flex",
    justifyContent: "center",
  },
  etlFeatureTextGrid: {
    paddingTop: "0.8rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem",
    },
  },
});

export class ProductFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }
  render() {
    const { classes, featureData } = this.props;

    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={6} className={classes.mainLeftGrid}>
              <Stack spacing={0}>
                {featureData &&
                  featureData.map((item, index) => {
                    if (index <= 1) {
                      return (
                        <Grid container className={classes.etlFeatureGrid}>
                          <Grid
                            item
                            xs={2}
                            className={classes.etlFeatureImageGrid}
                          >
                            <img
                              alt="feature image 1"
                              src={item.imagePath}
                              className={classes.etlFeatureImage}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            className={classes.etlFeatureTextGrid}
                          >
                            <Typography
                              variant="h2"
                              className={classes.etlFeatureTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              className={classes.etlFeatureSubHeading}
                            >
                              {item.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} className={classes.mainRightGrid}>
              <Stack spacing={0}>
                {featureData &&
                  featureData.map((item, index) => {
                    if (index > 1) {
                      return (
                        <Grid container className={classes.etlFeatureGrid}>
                          <Grid
                            item
                            xs={2}
                            className={classes.etlFeatureImageGrid}
                          >
                            <img
                              alt="feature image 2"
                              src={item.imagePath}
                              className={classes.etlFeatureImage}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            className={classes.etlFeatureTextGrid}
                          >
                            <Typography
                              variant="h2"
                              className={classes.etlFeatureTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              className={classes.etlFeatureSubHeading}
                            >
                              {item.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ProductFeatures);
