import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../layouts/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import OurTeams from "../data/ourteam.json";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TeamDetails from "../data/teamDetails.json";
import { NavLink } from "react-router-dom";
// import classes from '*.module.css';
// import ArrowForward from '@material-ui/icons/ArrowForward'
// import AboutSection from '../components/pagesComponents/aboutUsSection'
import BackgroundImage from "../assets/images/img_about-us-sky@2x.png";
import BackgroundImageCresecent from "../assets/images/shape__about-us-crescent-top.png";
import MissionBackground from "../assets/images/img_about-us-fade@2x.png";
import OurTeamBackground from "../assets/images/shape_half-crescent-2@2x.png";
import OurStory from "../assets/images/aboutus-page/Timeline-IAS.png";
import DundalkTeam from "../assets/images/aboutus-page/Dundalk-Team.png";

import ContactUs from "./ContactUs";

import HighlightOff from "@material-ui/icons/HighlightOff";
import Tooltip from "@material-ui/core/Tooltip";
import { Helmet } from "react-helmet-async";
import DiscoverSection from "../components/pagesComponents/DiscoverSection";
import EmployeeDetails from "../components/pagesComponents/EmployeeDetails";

const styles = (theme) => ({
  videoMainGrid: {
    // background:
    //   "transparent linear-gradient(180deg, #0278EF 0%, #0278ef14 65%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    height: "72vh",
    padding: "1em 0",
    margin: "0em 0 0 0",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      background: "transparent",
    },
    "@media (min-width: 760px)": {
      height: "auto",
      background: "transparent",
      margin: "7em 0 0 0",
    },
    "@media (min-width: 1200px)": {
      margin: "0em 0 0 0",
    },
  },
  carouselBlockBackgroundImg: {
    position: "relative",
    right: "15%",
    height: 700,
    width: 600,
    bottom: "10%",
    maxWidth: "initial",
    [theme.breakpoints.down("xs")]: {
      right: 0,
    },
  },
  aboutPageLinkTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    margin: "0 0 0.5em 0",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 40,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
  },
    [theme.breakpoints.up("md")]: {
      fontSize: 35,
    },
  },
  aboutPageLinkTitleDesc: {
    color: "#000",
    paddingLeft: "0.3em",
    paddingBottom: "1em",
    fontWeight: 100,
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // background: 'green'
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red',
      width: "100%",
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      // background: 'blue',
      width: "95%",
      fontSize: 20,
    },
  },
  aboutPageCeoNameText: {
    color: "#000",
    paddingLeft: "0.5em",
    paddingBottom: "1em",
    fontWeight: 100,
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // background: 'green'
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red',
      width: "100%",
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      // background: 'blue',
      width: "95%",
      fontSize: 20, // fontSize: 25,
    },
  },
  aboutPageCeoText: {
    color: "grey",
    marginTop: "-1em",
    paddingLeft: "0.5em",
    paddingBottom: "1em",
    fontWeight: 100,
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // background: 'green'
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red',
      width: "100%",
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      // background: 'blue',
      width: "95%",
      fontSize: 20, // fontSize: 25,
    },
  },
  aboutPageLinkworkDesc: {
    // fontSize: '0.9rem',
    fontSize: 23, // fontSize: 27,
    fontWeight: 100,
    padding: "10px 30px 10px 0",
    // textAlign: 'justify',
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 10px",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  aboutPageLinkworkDescOpening: {
    // fontSize: '0.9rem',
    fontSize: 27,
    fontWeight: 100,
    padding: "10px 30px 10px 0",
    // textAlign: 'justify',
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 10px",
      fontSize: "1rem",
    },
  },
  commonButtonStyle: {
    textTransform: "inherit",
    width: 400, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  applyHereButtonStyle: {
    background: "#B41E1C",
    fontFamily: "S-Core-CoreSansA65Bold",
    color: "#fff",
    fontWeight: "none",
    textTransform: "inherit",
    fontSize: 23,
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 200,
      fontSize: 23,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
      fontSize: 23,
    },
  },
  discoveryButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: "1em",
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20,
    },
  },
  abouttButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 2em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
  DemoButtonStyle: {
    background: "#B41E1C",
    color: "#fff",
    // border: '1px solid #B41E1C',
    fontFamily: "S-Core-CoreSansA65Bold",
    marginLeft: "2.7em",
    fontSize: 24, // fontSize: 30,
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
      marginLeft: 0,
      fontSize: "0.9rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 22,
      marginLeft: "2.4em",
      // width: '100%',
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25, // fontSize: 30,
      marginLeft: "2.7em",
    },
  },
  productTabLabel: {
    textTransform: "capitalize",
    fontSize: 25,
    fontFamily: "AvenirLTStd-Roman",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1em",
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  productTabSelected: {
    color: "#000 !important",
  },
  // aboutUsMainBackgroundGrid: {
  //   background: `transparent url(${BackgroundImageCresecent}) 100% 116% no-repeat padding-box`,
  //   [theme.breakpoints.between("sm", "md")]: {
  //     background: `transparent url(${BackgroundImageCresecent}) 73% 111% no-repeat padding-box`,
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     background: `transparent url(${BackgroundImageCresecent}) 100% 116% no-repeat padding-box`,
  //   },
  // },
  // backgroundDesignOurMission: {
  //   background: `transparent url(${MissionBackground}) 120% 0% no-repeat padding-box`,
  //   backgroundSize: "contain",
  //   [theme.breakpoints.down("xs")]: {
  //     background: "transparent",
  //   },
  //   [theme.breakpoints.between("sm", "md")]: {
  //     background: `linear-gradient(45deg, #fffffffc, #ffffff7a), url(${MissionBackground}) 120% 0% no-repeat padding-box`,
  //     backgroundSize: "cover",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     background: `transparent url(${MissionBackground}) 120% 0% no-repeat padding-box`,
  //     backgroundSize: "contain",
  //   },
  // },
  backgroundDesignOurTeams: {
    padding: "0 0 2em 0",
    // background: `transparent url(${OurTeamBackground}) -650px 40% no-repeat padding-box`,
    // backgroundSize: "110% 110%",
    // padding: "0 3em 0 0em",
    // [theme.breakpoints.down("xs")]: {
    //   padding: "0 1em",
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   background: `transparent url(${OurTeamBackground}) -300px 30% no-repeat padding-box`,
    //   backgroundSize: "contain",
    // },
    // [theme.breakpoints.up("md")]: {
    //   background: `transparent url(${OurTeamBackground}) -650px 40% no-repeat padding-box`,
    //   backgroundSize: "110% 110%",
    // },
  },
  ourMissionGridBlock: {
    marginBottom: "3em",
    marginTop: "3em",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  ourTeamGridBlock: {
    // padding: '3em',
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  aboutLinksSection: {
    position: "relative",
    // bottom: '100%',
    // right: '1.5em',
    // display: 'flex',
    // justifyContent: 'space-evenly'
    padding: "1em 0 1em 2.5em",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      padding: "0 0 1em 0",
      bottom: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      bottom: "60%",
    },
  },
  linksListFirst: {
    paddingLeft: "0 !important",
  },
  linksList: {
    fontFamily: "S-Core-CoreSansA45Regular",
    color: "#666666c2",
    textDecoration: "none",
    padding: "0 1em",
    // fontSize: '1.4rem',
    fontSize: 35, // fontSize: 40,
    fontWeight: "lighter",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 1em",
      background: "#0278ef",
      // background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
      margin: "10px 0",
      borderRadius: 10,
      color: "#fff",
      fontSize: "1rem",
    },
  },
  teamImageProfile: {
    width: 150,
    height: 150,
    borderRadius: "50%",
  },
  teamImageProfileExpand: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    marginLeft: 22,
  },
  teamProfileGrid: {
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    margin: "10px 0 10px 0",
    padding: "1em 2em 1em 1em",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  teamImageDesc: {
    // fontSize: '0.9rem',
    fontSize: 20, // fontSize: 23,
    padding: "10px 30px 10px 0",
    fontFamily: "S-Core-CoreSansA35Light",
    color: "#223140",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 0",
    },
  },
  workForUsItem: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  expandTeamIcon: {
    color: "#2196f3",
    fontSize: 17,
    marginLeft: 10,
    cursor: "pointer",
  },
  aboutPageTitle: {
    // textAlign: 'left',
    // letterSpacing: '0.66px',
    color: "#223140",
    marginTop: "10%",
    padding: "0 0.9em",
    width: "90%",
    fontFamily: "S-Core-CoreSansA55Medium",
    // fontSize: '2.5rem',
    fontSize: 64, // fontSize: 69,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      padding: 0,
      textAlign: "center",
      fontSize: "2.5rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "3rem",
      width: "100%",
      // padding: '0 1em',
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 64, // fontSize: 69,
      // padding: '0 1em',
    },
  },
  aboutPageTitledesc: {
    padding: "2em 1em 1em 2.5em",
    // fontSize: '0.9rem',
    fontSize: 25, // fontSize: 28,
    fontWeight: 100,
    width: "85%",
    color: "#223140",
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      fontSize: "1rem",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
      padding: "2em 1em 1em 2em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25, // fontSize: 28,
    },
  },
  // mainButtonContainer: {
  //   // justifyContent: 'space-around',
  //   marginTop: "2em",
  //   marginLeft: 0,
  //   marginBottom: "15em",
  //   [theme.breakpoints.down("xs")]: {
  //     justifyContent: "space-around",
  //     marginLeft: 0,
  //     marginBottom: 0,
  //     // background: 'green'
  //   },
  //   [theme.breakpoints.between("sm", "md")]: {
  //     // justifyContent: 'space-around',
  //     // background: 'red'
  //     marginLeft: 0,
  //   },
  // },
  aboutFloatingImageBG: {
    paddingTop: "7em",
    justifyContent: "center",
    display: "flex",
    // alignItems: 'center'
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0.5em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 0 0 0",
    },
  },
  missionFloatingImage: {
    height: 250,
    width: 350,
    borderRadius: "15px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
    },
  },
  workForUsItemContainer: {
    marginLeft: "1em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  workForUsImg: {
    width: 110,
    height: 110,
    position: "relative",
    left: "-8%",
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
      left: 0,
    },
  },
  teamBottomLine: {
    height: 1,
    background: "#9e9e9e",
    width: "100%",
    margin: "1em 0 0 0",
  },
  aboutPageLinkMissionTitle: {
    margin: "0 0 0 0em",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  carrerSection: {
    padding: "0 0 1.5em 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0em 0 0",
    },
  },
  aboutPageLinkworkTitle: {
    fontSize: 30,
    marginTop: 16,
    fontWeight: 400,
    textAlign: "left",
    fontFamily: "S-Core-CoreSansA45Regular",
    lineHeight: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem",
      wordBreak: "break-all",
      lineHeight: "inherit",
    },
    [theme.breakpoints.between("sm", "md")]: {
      wordBreak: "break-all",
      lineHeight: "inherit",
    },
  },
  aboutUsMainGridBlock: {
    padding: "5em 1.5em 0 1.8em",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0em 1.5em 0 1.8em",
    },
  },
  aboutUsMainBackgroundGrid: {
    // background: `transparent url(${BackgroundImageTop}) 100% 114% no-repeat padding-box`,
    padding: "0em 0 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 0 0",
    },
  },
  aboutUsPageTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "0 0em 0 0.3em",
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 25,
      textAlign: "left",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "80%",
      marginTop: "10%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 35,
    },
    [theme.breakpoints.up("lg")]: {
      width: "93%",
      fontSize: 35,
    },
  },
  aboutUsPageTitledesc: {
    padding: "2em 1em 3em 0em",
    fontSize: 25,
    fontWeight: 100,
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 16,
      padding: "1em 0 0 0.5em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "89%",
      fontSize: 20,
      padding: "2em 2em 1em 0.5em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25, // fontSize: 27,
    },
    [theme.breakpoints.up("lg")]: {
      width: "85%",
      fontSize: 20, // fontSize: 27,
    },
  },
  mainButtonContainer: {
    padding: "1em",
    [theme.breakpoints.down("xs")]: {
      // justifyContent: "space-around",
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // justifyContent: "space-around",
      // background: 'red'
    },
  },
  discoveryButtonGrid: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 1em 0",
      justifyContent: "center",
    },
    [theme.breakpoints.down("lg")]: {
      justifyContent: "left",
    },
  },
  demoButtonGrid: {
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  demoButtonStyle: {
    textTransform: "inherit",
    fontFamily: "AvenirLTStd-Roman",
    width: 200, // width: 200,
    fontSize: 20, // fontSize: 30,
    background: "#fff",
    color: "#B41E1C",
    border: "1px solid #B41E1C",
    fontWeight: "none",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
      margin: "1em 0 0 0em",
      fontSize: 15, // fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 200,
      margin: "2em 0 0 0em",
    },
  },
  tabContainerTabs: {
    marginTop: "1em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  teamEmployeeMainGrid: {
    paddingTop: "0.5em",
  },
  employeeMainBigScreenGrid: {
    "@media (min-width: 300px)": {
      display: "none",
    },
    "@media (min-width: 1200px)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: "0 0 0 3em",
    },
  },
  employeeMainGrid: {
    display: "flex",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      justifyContent: "center",
      margin: "0em 0em 0 1em",
    },
    "@media (width: 760px)": {
      flexDirection: "row",
      justifyContent: "left",
      margin: "1em 0em 0 0em",
    },
    "@media (width: 820px)": {
      flexDirection: "row",
      justifyContent: "center",
      padding: "0 0 0 0em",
      margin: "1em 0em 0 -1em",
    },
    "@media (width: 912px)": {
      flexDirection: "row",
      justifyContent: "center",
      padding: "0 0 0 0em",
      margin: "1em 0em 0 0.5em",
    },
    "@media (min-width: 1200px)": {
      display: "none",
    },
  },
  storyImageGrid: {
    display: "flex",
    justifyContent: "center",
  },
  storyImageStyle: {
    height: "85%",
    width: "85%",
    marginTop: "2em",
  },
  aboutUsDemoButtonStyle: {
    fontFamily: "AvenirLTStd-Roman",
    textTransform: "inherit",
    width: 210, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  buttonTextStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    marginTop: "3em",
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 22,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20, // fontSize: 30,
      marginLeft: 0,
    },
  },
  joinTextStyle: {
    fontFamily: "AvenirLTStd-Book",
    fontSize: 19,
    width: "95%",
  },
  joinImageGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "3em",
    },
  },
  joinImageStyle: {
    borderRadius: "5rem",
    objectFit: "contain",
    height: "70%",
    widht: "100%",
  },
  discoverLink: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    marginLeft: "1.8em",
    // fontSize: '0.9rem',
    fontSize: 19, // fontSize: 25,
    color: "#cc0000",
    textDecoration: "underline",
    letterSpacing: 0,
    width: "100%",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
});

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
      teamMemberDialogopen: false,
      teammemberExpandDetails: null,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    // console.log(this.props)
    // this.loadnpm ()
    this.scrollContactSection();
  }

  scrollContactSection = () => {
    const { location } = this.props;
    const hash = location.hash;
    if (hash === "" || hash === "#" || hash === undefined) return false;
    const target = window.jQuery(hash);
    // target = target.length ? target : window.jQuery('[name=' + this.hash.slice(1) +']');
    if (target.length) {
      window
        .jQuery("html,body")
        .stop()
        .animate(
          {
            scrollTop: target.offset().top - 50, //offsets for fixed header
          },
          "linear"
        );
    }
  };

  expandTeammember = (data) => {
    this.setState({
      teamMemberDialogopen: true,
      teammemberExpandDetails: data,
    });
  };

  handleCloseTeammember = () => {
    this.setState({
      teamMemberDialogopen: false,
    });
  };

  aboutLinkActiuve = (evt) => {
    let links = document.querySelectorAll(".abt-links");
    let arrayLinks = Array.from(links);
    arrayLinks.map((item) => {
      item.classList.remove("abt-link-active");
    });
    evt.currentTarget.classList.add("abt-link-active");
  };
  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Demo Request");
    const email = "sales@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { teammemberExpandDetails, teamMemberDialogopen } = this.state;
    return (
      <Layout>
        <Helmet>
          <title>About REDiFly</title>
          <meta
            name="description"
            contents="More info related to our REDiFly Team and our goals."
          />
          <link rel="canonical" href="/about" />
        </Helmet>
        <div>
          <Grid container className={classes.aboutUsMainGridBlock}>
            <Grid container>
              <Grid
                item
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes.aboutUsMainBackgroundGrid}
                // data-aos="zoom-in"
              >
                <Typography variant="h1" className={classes.aboutUsPageTitle}>
                  Streamlining operations through the power of data and bespoke
                  software solutions.
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.aboutUsPageTitledesc}
                >
                  We offer much more than just software, we provide bespoke
                  solutions that integrate with and enhance current workflows.
                  Implementing new technology is no small feat, which is
                  why we’re here to guide you each step of the way.​
                </Typography>
                <Grid container className={classes.mainButtonContainer}>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className={classes.discoveryButtonGrid}
                  >
                    <Button
                      variant="contained"
                      href="https://calendly.com/redifly/discoverycall"
                      target="_blank"
                      className={[
                        classes.commonButtonStyle,
                        classes.discoveryButtonStyle,
                      ].join(" ")}
                    >
                      Schedule a Discovery Call
                    </Button>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={10}
                    xs={12}
                    className={classes.demoButtonGrid}
                  >
                    <Button
                      variant="contained"
                      onClick={this.handleEmailClick}
                      className={classes.demoButtonStyle}
                    >
                      Book a Demo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes.videoMainGrid}
                // data-aos="zoom-in-down"
              >
                <div class="ratio ratio-16x9">
                  <iframe
                    src="https://www.youtube.com/embed/rt8i05UkvFM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    style={{ borderRadius: "20px" }}
                  ></iframe>
                </div>
                {/* <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    class="embed-responsive-item"
                    src="https://www.youtube.com/embed/_0tavthjNXw"
                  ></iframe>
                </div> */}
                {/* <div>
                  <img
                    src={BackgroundImage}
                    alt="view of clouds from aircraft"
                    className={classes.carouselBlockBackgroundImg}
                  />
                </div> */}
              </Grid>
            </Grid>
            {/* <Grid item lg={6} md={6} sm={6} xs={12} data-aos="fade-in">
              <Typography variant="h5" className={classes.aboutLinksSection}>
                <a
                  className={[
                    classes.linksList,
                    "abt-links",
                    classes.linksListFirst,
                  ].join(" ")}
                  href="#about"
                  onClick={this.aboutLinkActiuve.bind(this)}
                >
                  About
                </a>
                <a
                  className={[classes.linksList, "abt-links"].join(" ")}
                  href="#contact"
                  onClick={this.aboutLinkActiuve.bind(this)}
                >
                  Contact
                </a>
                <a
                  className={[classes.linksList, "abt-links"].join(" ")}
                  href="#carrer"
                  onClick={this.aboutLinkActiuve.bind(this)}
                >
                  Careers
                </a>
              </Typography>
            </Grid> */}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.ourMissionGridBlock}
              // data-aos="fade-in"
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography
                    variant="h2"
                    className={classes.aboutPageLinkTitle}
                  >
                    {OurTeams && OurTeams.missionTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.aboutPageLinkTitleDesc}
                    dangerouslySetInnerHTML={{
                      __html: OurTeams && OurTeams.missionDesc,
                    }}
                  />
                  <Typography
                    variant="body2"
                    className={classes.aboutPageCeoNameText}
                  >
                    Patrick Clancy
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.aboutPageCeoText}
                  >
                    CEO
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  className={classes.aboutFloatingImageBG}
                >
                  <img
                    alt="floating"
                    src={OurTeams && OurTeams.missionFloatingImage}
                    className={classes.missionFloatingImage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // data-aos="fade-in"
              className={classes.ourTeamGridBlock}
            >
              <Typography
                variant="h3"
                className={[
                  classes.aboutPageLinkTitle,
                  classes.aboutPageLinkMissionTitle,
                ].join(" ")}
              >
                {OurTeams && OurTeams.teamTitle}
              </Typography>
              <Grid container className={classes.backgroundDesignOurTeams}>
                <Tabs
                  value={this.state.value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleChange}
                  variant="scrollable"
                  classes={{ indicator: "product-tabs-indicator" }}
                  className={classes.tabContainerTabs}
                  TabIndicatorProps={{ style: { position: "relative" } }}
                >
                  {TeamDetails &&
                    TeamDetails.map((item, index) => {
                      return (
                        <Tab
                          key={index}
                          classes={{
                            wrapper: classes.productTabLabel,
                            selected: classes.productTabSelected,
                          }}
                          label={item.name}
                          className="cool-link"
                        />
                      );
                    })}
                </Tabs>
                {TeamDetails &&
                  TeamDetails.map((item, index) => {
                    return (
                      value === index && (
                        <Grid className={classes.teamEmployeeMainGrid}>
                          <Grid container>
                            <Grid item xs={12} sm={12} lg={12}>
                              <Grid
                                className={classes.employeeMainBigScreenGrid}
                              >
                                {item.children.map((employee, index) => {
                                  if (
                                    item.id === employee.location &&
                                    index <= 4
                                  ) {
                                    return (
                                      <EmployeeDetails employee={employee} />
                                    );
                                  }
                                })}
                              </Grid>
                              <Grid
                                className={classes.employeeMainBigScreenGrid}
                              >
                                {item.children.map((employee, index) => {
                                  if (
                                    item.id === employee.location &&
                                    index > 4
                                  ) {
                                    return (
                                      <EmployeeDetails employee={employee} />
                                    );
                                  }
                                })}
                              </Grid>
                              <Grid className={classes.employeeMainGrid}>
                                {item.children.map((employee, index) => {
                                  if (
                                    item.id === employee.location &&
                                    index <= 2
                                  ) {
                                    return (
                                      <EmployeeDetails employee={employee} />
                                    );
                                  }
                                })}
                              </Grid>
                              <Grid className={classes.employeeMainGrid}>
                                {item.children.map((employee, index) => {
                                  if (
                                    item.id === employee.location &&
                                    index > 2 &&
                                    index <= 5
                                  ) {
                                    return (
                                      <EmployeeDetails employee={employee} />
                                    );
                                  }
                                })}
                              </Grid>
                              <Grid className={classes.employeeMainGrid}>
                                {item.children.map((employee, index) => {
                                  if (
                                    item.id === employee.location &&
                                    index > 5
                                  ) {
                                    return (
                                      <EmployeeDetails employee={employee} />
                                    );
                                  }
                                })}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            {item.children.map((employee, index) => {
                              if (item.id === "lacoruna" && index === 0) {
                                return (
                                  <a
                                    href={item.href}
                                    className={classes.discoverLink}
                                    target="_blank"
                                  >
                                    {" "}
                                    View the full Coruña Team
                                  </a>
                                );
                              }
                            })}
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
                {/* {OurTeams &&
                  OurTeams.teams &&
                  OurTeams.teams.map((item, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className={[
                          classes.teamProfileGrid,
                          "team-member-profile",
                        ].join(" ")}
                        data-aos={index % 2 !== 0 ? "flip-right" : "flip-left"}
                      >
                        <Typography
                          variant="h6"
                          className="team-member-profile-name"
                          style={{
                            marginTop: 10,
                            fontWeight: 300,
                            fontFamily: "S-Core-CoreSansA45Regular",
                          }}
                        >{`${item.firstName} ${item.lastName}`}</Typography>
                        <Typography
                          variant="body1"
                          className="team-member-profile-role"
                          style={{
                            fontFamily: "S-Core-CoreSansA35Light",
                            color: "#707070",
                          }}
                        >
                          {item && item.role}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={[
                            classes.teamImageDesc,
                            "team-member-profile-desc",
                          ].join(" ")}
                        >
                          {item.shortDescription}
                        </Typography>
                        <hr className={classes.teamBottomLine} />
                      </Grid>
                    );
                  })} */}
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                id="ourstory"
                // data-aos="fade-in"
                className={classes.ourTeamGridBlock}
              >
                <Typography
                  variant="h3"
                  className={[
                    classes.aboutPageLinkTitle,
                    classes.aboutPageLinkMissionTitle,
                  ].join(" ")}
                >
                  Our Story
                </Typography>
                <Grid className={classes.storyImageGrid}>
                  <img src={OurStory} className={classes.storyImageStyle} />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} id="contact">
                <ContactUs />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                id="career"
                // data-aos="fade-right"
                className={classes.carrerSection}
              >
                <Typography
                  variant="h4"
                  className={classes.aboutPageLinkTitle}
                  style={{ marginTop: "1em" }}
                >
                  Join Our Team
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Typography
                      variant="body2"
                      className={classes.joinTextStyle}
                    >
                      The digital transformation in aviation has accelerated in
                      recent years and our team has been growing along with it.
                      We firmly believe in fostering a continuous learning
                      environment and in encouraging team members to grow and
                      develop their own skillsets. We are currently at a vital
                      stage in our development, and you will have the
                      opportunity to influence the growth of a young and
                      fast-moving company while being rewarded for your work.
                      <br /> <br /> We offer much more than just software, we
                      provide bespoke, personalised solutions that integrate
                      with and enhance your current workflows along with the
                      training and service that is necessary to support the
                      project. We understand that changing or implementing
                      new technology is no small feat, which is why we’re here
                      to guide you each step of the way.​
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={this.handleEmailClick}
                      className={[
                        classes.aboutUsDemoButtonStyle,
                        classes.buttonTextStyle,
                      ].join(" ")}
                    >
                      Book a Demo
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    xl={5}
                    className={classes.joinImageGrid}
                  >
                    <img src={DundalkTeam} className={classes.joinImageStyle} />
                  </Grid>
                </Grid>
                {/* <Grid container className={classes.workForUsItemContainer}>
                  {workForUsData &&
                    workForUsData.data &&
                    workForUsData.data.map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={12}
                          key={index}
                          className={classes.workForUsItem}
                        >
                          <img
                            alt="work"
                            src={item.imagePath}
                            className={classes.workForUsImg}
                          />
                          <Typography
                            variant="h6"
                            className={[classes.aboutPageLinkworkTitle].join(
                              " "
                            )}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={[classes.aboutPageLinkworkDesc].join(
                              " "
                            )}
                            style={{
                              textAlign: "left",
                              marginTop: "1em",
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid> */}
                {/* <Grid container style={{ marginTop: "2em" }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="h4"
                      className={classes.aboutPageLinkTitle}
                      style={{
                        marginTop: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {workForUsData && workForUsData.openPositionTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.aboutPageLinkworkDescOpening}
                      dangerouslySetInnerHTML={{
                        __html: workForUsData && workForUsData.openPositionDesc,
                      }}
                    />
                    <div
                      style={{
                        textAlign: "center",
                        margin: "1em 0",
                      }}
                    >
                      <Button
                        href="mailto:ias@redifly.com?subject=Job Application &body= I would like to apply for the position of!"
                        target="blank"
                        variant="contained"
                        className={[
                          classes.commonButtonStyle,
                          classes.applyHereButtonStyle,
                        ].join(" ")}
                      >
                        Send your CV
                      </Button>
                    </div>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <DiscoverSection />
          {/* <Dialog onClose={this.handleCloseTeammember} open={teamMemberDialogopen}>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item lg={3} md={3} sm={3} xs={12} data-aos="zoom-in">
                <img alt="profile" src={teammemberExpandDetails && teammemberExpandDetails.imagePath} className={classes.teamImageProfileExpand} /> 
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Typography variant="h6" style={{marginTop: 10, fontWeight: 400}}>{`${teammemberExpandDetails && teammemberExpandDetails.firstName} ${teammemberExpandDetails && teammemberExpandDetails.lastName}`}</Typography>
                <Typography variant="body1" style={{fontSize: 14}}>{teammemberExpandDetails && teammemberExpandDetails.role}</Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={12}>
                <IconButton onClick={this.handleCloseTeammember}  className={classes.button} aria-label="Delete"><HighlightOff style={{color: '#1976d2'}} /></IconButton>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-in"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                <Typography variant="body2" className={classes.teamImageDesc}>{teammemberExpandDetails && teammemberExpandDetails.description}</Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(AboutPage);
