import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@mui/material/Divider";
// import classes from '*.module.css';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import WebinarCover from "../../assets/images/etl-page/WebinarCoverImage.png";
import CaseStudy from "../../assets/images/etl-page/CaseStudyImage.png";
import ETLBrochure from "../../assets/pdfs/REDiFly eTechlog Brochure - 2023.pdf";
import { NavLink } from "react-router-dom";
import EmailModal from "./EmailPopUpModal";

const styles = (theme) => ({
  mainGrid: {
    display: "flex",
    flexDirection: "column",
    justifiyContent: "center",
    alignItems: "center",
    paddingBottom: "5rem",
  },
  mainHeadingGrid: {
    paddingTop: "0rem",
    paddingBottom: "1rem",
  },
  mainHeading: {
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 40,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 40,
    },
  },
  discoverItemGridContainer: {
    margin: "auto",
    paddingTop: "3rem",
    [theme.breakpoints.between("xs", "sm")]: {
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'yellow'
    },
  },
  discoverItemGrid: {
    display: "flex",
    margin: "auto",
    maxWidth: "30rem",
    height: "15.5rem",
    backgroundColor: "#fefbfb",
    borderRadius: "1.5rem",
    flexDirection: "column",
    alignItems: "Center",
    padding: "1.4em",
    [theme.breakpoints.down("xs")]: {
      // padding: "1em 0",
      marginBottom: "1rem",
      alignItems: "left",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "1em 0",
      marginBottom: "2rem",
      alignItems: "left",
    },
  },
  discoverItemMainImageGrid: {
    display: "flex",
    justifiyContent: "center",
  },
  discoverItemGridImage: {
    width: "150%",
  },
  discoverLink: {
    marginTop: "1em",
    marginLeft: "auto", 
    marginRight: "auto",

    // fontSize: '0.9rem',
    fontSize: 19, // fontSize: 25,
    color: "#cc0000",
    textDecoration: "underline",
    letterSpacing: 0,
    width: "100%",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
});

class DiscoverSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  handleDownloadClick() {
    const pdfFile = ETLBrochure;
    const anchor = document.createElement("a");
    anchor.href = pdfFile;
    anchor.download = "REDiFly eTechlog Brochure - 2023";
    anchor.click();
  }

  handleOpenModal = (source) => {
    this.setState({ modalOpen: true , modalSource:source});
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Grid className={classes.mainHeadingGrid}>
            {/* <Typography className={classes.mainHeading}>
              Discover More
            </Typography> */}
          </Grid>
          <Grid container className={classes.discoverItemGridContainer}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className={classes.discoverItemGrid}
            >
              <Grid className={classes.discoverItemMainImageGrid}>
                <img
                  alt="Not available"
                  src={WebinarCover}
                  className={classes.discoverItemGridImage}
                />
              </Grid>
              <Typography variant="body2">
                <a
                  className={classes.discoverLink}
                  onClick={() => this.handleOpenModal("Webinar")}
                >
                  {" "}
                  Watch our Webinar{" "}
                </a>
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className={classes.discoverItemGrid}
            >
              <Grid className={classes.discoverItemMainImageGrid}>
                <img
                  alt="aircraft technician in yellow vest carrying out an inspection"
                  src={CaseStudy}
                  className={classes.discoverItemGridImage}
                />
              </Grid>
              <Typography variant="body2">
                <a
                  className={classes.discoverLink}
                  onClick={() => this.handleOpenModal("Case Study")}
                >
                  {" "}
                  Download our Case Study{" "}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <EmailModal
            open={this.state.modalOpen}
            handleClose={this.handleCloseModal}
            source={this.state.modalSource}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(DiscoverSection);
