import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TalkToUsBackground from "../../assets/images/talktous-background-homepage.png";

const styles = (theme) => ({
  mainGrid: {
    display: "flex",
    flexDirection: "column",
    justifiyContent: "center",
    alignItems: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  mainHeadingGrid: {
    display: "flex",
    fontFamily: "AvenirLTStd-Roman",
    justifiyContent: "center",
    paddingTop: "0rem",
    paddingBottom: "1rem",
    background: `url(${TalkToUsBackground}) 100% 7% no-repeat padding-box`,
    backgroundSize: "contain",
  },
  mainHeading: {
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 40,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 40,
    },
  },
  mainTalkDescriptionGrid: {
    padding: "1rem",
    fontFamily: "AvenirLTStd-Book",
  },
  talkDescriptionGrid: {
    display: "flex",
    fontFamily: "AvenirLTStd-Book",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  talkDescriptionStyle: {},
  bookButtonGrid: {
    paddingTop: "0.5rem",
  },
  bookDemoButton: {
    background: "#B41E1C",
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: "AvenirLTStd-Roman",
    marginTop: "2em",
    fontWeight: "none",
    fontSize: 15, //fontSize: 30,
    borderRadius: 5,
    width: 250,
    [theme.breakpoints.down("xs")]: {
      width: 300,
      fontSize: "0.9rem",
      marginLeft: 0,
    },
  },
  lineStyle: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
});

class TalktoUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Grid className={classes.mainHeadingGrid}>
            <Typography className={classes.mainHeading}>Talk to Us</Typography>
          </Grid>
          <Grid container className={classes.mainTalkDescriptionGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.talkDescriptionGrid}>
                Schedule a no-obligation, 15-minute call at your convenience to
                learn more
              </Typography>
              <Typography className={classes.talkDescriptionGrid}>
                and discover how our software can benefit your operations.
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.bookButtonGrid}>
            <Button
              variant="contained"
              href="https://calendly.com/redifly/discoverycall"
              target="_blank"
              color="primary"
              className={classes.bookDemoButton}
            >
              Schedule a Discovery Call
            </Button>
          </Grid>
          <Grid className={classes.lineStyle}></Grid>
          <span className="line"></span>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(TalktoUs);
