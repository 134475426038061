import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import { setupHeaderBg } from '../../assets/js/main';
// import Logo from './Logo';
// import Grid from '@material-ui/core/Grid'
// import NavLinks from './NavLinks';
// import IASLogo from '../../assets/images/logo.png'
import { NavLink } from "react-router-dom";
// import Collapse from '@material-ui/core/Collapse'
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Cancel";

const styles = (theme) => ({
  drawerMenuContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    background: "#333333",
    color: "#fff",
    opacity: "0.7",
  },
  menuBarContainer: {
    padding: "1em",
  },
  firstLevelMenus: {
    listStyle: "none",
    paddingLeft: 0,
  },
  firstLevelMenusLists: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flexStart",
  },
  hoverFirstLevelLists: {
    "&:hover": {
      color: "#216698",
      background: "#fff",
    },
  },
  subLevelMenus: {
    listStyle: "none",
  },
  subLevelMenusLists: {
    margin: "1em 0",
    padding: "0 10px",
    color: "white",
    "&:hover": {
      color: "#216698",
      background: "#fff",
    },
  },
  firstLevelMenusListsAnchor: {
    textDecoration: "none",
    fontFamily: "S-Core-CoreSansA45Regular",
    fontSize: 24,
  },
  subLevelMenusListsAnchor: {
    color: "white",
    textDecoration: "none",
    fontFamily: "S-Core-CoreSansA45Regular",
    fontSize: 24,
  },
  closeDrawerContainer: {
    marginBottom: 0,
    textAlign: "right",
    padding: "1em 1em 0 0",
  },
  closeDrawer: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
  faIconStyle: {
    marginRight: 10,
  },
});

class DrawerMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
    };
  }

  // returnMenusStrucutre = (links, classes) => {
  //   // let content = '';
  //   // links.map((item,index) => {
  //   //   content += (<li><NavLink to={item.href}>{item.text}</NavLink></li>)
  //   // })
  //   // console.log(conte)
  //   // return content

  //   return(
  //     links.map((item,index) => {
  //       return(
  //       <li>
  //         <NavLink to={item.href}>{item.text}</NavLink>
  //         {item.children (

  //         )}
  //       </li>
  //       )
  //     })
  //   )
  // }
  render() {
    const { classes, open, headerData } = this.props;
    // const { subMenuOpen } = this.state
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={this.props.toggleDrawer(!open)}
        className={classes.drawerMenuContainerBlock}
        classes={{ paperAnchorRight: classes.drawerMenuContainer }}
      >
        <div
          tabIndex={0}
          role="button"
          style={{ outline: "none" }}
          // data-aos="fade-zoom-in"
          // data-aos-easing="ease-in-back"
          // data-aos-delay="10"
        >
          <p className={classes.closeDrawerContainer}>
            <Close
              className={classes.closeDrawer}
              onClick={this.props.toggleDrawer(!open)}
            />
          </p>
          <nav className={classes.menuBarContainer}>
            <ul className={classes.firstLevelMenus}>
              {headerData.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={[
                      classes.firstLevelMenusLists,
                      item.children ? "" : classes.hoverFirstLevelLists,
                    ].join(" ")}
                  >
                    <i
                      className={["fa", item.faIcon, classes.faIconStyle].join(
                        " "
                      )}
                      aria-hidden="true"
                    />
                    <NavLink
                      to={item.children ? "#" : item.href}
                      className={classes.firstLevelMenusListsAnchor}
                    >
                      <span style={{ color: "white" }}>{item.text}</span>
                      {
                        <ul className={classes.subLevelMenus}>
                          {item.children &&
                            item.children.map((subItem, subIndex) => {
                              return (
                                <li
                                  key={subIndex}
                                  className={classes.subLevelMenusLists}
                                >
                                  <NavLink
                                    to={subItem.href}
                                    className={classes.subLevelMenusListsAnchor}
                                  >
                                    {subItem.text}
                                  </NavLink>
                                </li>
                              );
                            })}
                        </ul>
                      }
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(DrawerMenus);
