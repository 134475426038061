import React, { Component } from "react";
import { Link } from "react-router-dom";
import { loadHeaderStickymenus } from "../../assets/js/main";
import IASLogo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/icons/Menu";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";
import DrawerMenu from "./DrawerMenus";
import EtlProductMenus from "./EtlProductMenus";
import headerData from "../../data/headermenus.json";
import etlCategories from "../../data/headerMenusCategories.json";
import opsCategories from "../../data/opsHeaderMenusCategories.json";
import OpsProductMenus from "./OpsProductMenus";

const styles = (theme) => ({
  CollapseContainer: {
    position: "relative",
    bottom: "4.5em",
    border: "1px solid #3e3e3e2e",
    borderRight: "none",
    borderLeft: "none",
  },
  wrapperInner: {
    textAlign: "right",
  },
  subMenuLists: {
    display: "inline-flex",
    margin: "10px 3em 10px 0",
  },
  submenuList: {
    margin: "0 2em",
    listStyle: "none",
  },
  headerLinksContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  hideLess960: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  showGreat960: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      // margin: '1em',
      textAlign: "right",
      padding: 10,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  productMenuContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  productItemBlock: {
    minHeight: "2em",
    fontFamily: "AvenirLTStd-Book",
    letterSpacing: "0.21px",
    color: "#707070",
    width: "75%",
    margin: "10px 0",
  },
});

// class EtlCategories extends Component() {
//   render() {
//     const { classes, header } = this.props;

//     return <div></div>;
//   }
// }

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      open: false,
    };
  }
  componentDidMount() {
    loadHeaderStickymenus();
    this.openProductMenus();
    // this.openOpsProductMenus();
    // setupHeaderBg();
    // console.log('sdgdgg')
  }

  openProductMenus = () => {
    const pageAccessedByReload = (
    window.performance.getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')
);
      window.jQuery(".header-nav li").hover(function () {
      if (window.jQuery(this).data("id") === "our-products") {
        window.jQuery("#etlMenu").toggle();
        window
          .jQuery(".main-header.large-screen")
          .toggleClass("active-product-menu-open");
      } else if (window.jQuery(this).data("id") === "ops-products") {
        window.jQuery("#opsMenu").toggle();
        window
          .jQuery(".main-header.large-screen")
          .toggleClass("active-product-menu-open");
      }
    });
    if (pageAccessedByReload) {
      if (window.jQuery(this).data("id") === "our-products") {
        window.jQuery("#etlMenu").toggle();
        window
          .jQuery(".main-header.large-screen")
          .toggleClass("active-product-menu-open");
      } else if (window.jQuery(this).data("id") === "ops-products") {
        window.jQuery("#opsMenu").toggle();
        window
          .jQuery(".main-header.large-screen")
          .toggleClass("active-product-menu-open");
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    // console.log(this.props.children !== nextProps.children)
    // console.log('this', this.props, 'next', nextProps)
    if (nextProps !== this.props) {
      if (
        nextProps.location &&
        nextProps.location.props &&
        nextProps.location.props.location &&
        nextProps.location.props.location.pathname
      ) {
        if (
          this.props.location.props.location.pathname !==
          nextProps.location.props.location.pathname
        ) {
          // console.log('dfsdg')
          this.setState({
            open: false,
          });
        }
      }
    }
    // console.log('header', nextProps)
  }

  openSubmenus = (status) => {
    this.setState({ subMenuOpen: !status });
  };

  toggleDrawer = (open) => () => {
    // console.log('adf')
    this.setState({
      open: open,
    });
  };

  render() {
    const { classes } = this.props;
    // const { subMenuOpen } = this.state
    // console.log('header', this.props)
    return (
      <React.Fragment>
        <header
          id="header"
          role="banner"
          className={["main-header large-screen", classes.hideLess960].join(
            " "
          )}
        >
          <div className="header-inner">
            <div className="header-logo">
              <Link to="/">
                <img
                  src={IASLogo}
                  alt="Creative Juiz"
                  width="120"
                  height="120"
                />
              </Link>
            </div>
            <nav className="header-nav">
              <ul>
                {headerData.map((header, index) => (
                  <React.Fragment key={index}>
                    {header.id !== "about-us" &&
                      header.id !== "home" &&
                      header.id !== "blog" && (
                        <li key={index} data-id={header.id}>
                          <React.Fragment id={header.id}>
                            <NavLink
                              to={header.href}
                              className={true ? classes.activeProductMenu : ""}
                            >
                              <span>{header.text}</span>
                              <DownArrow
                                style={{
                                  width: 20,
                                  position: "relative",
                                  top: -1,
                                  left: 5,
                                  height: 20,
                                }}
                              />
                            </NavLink>
                            {header.text === "ETL" ? (
                              <EtlProductMenus
                                subHeadersData={header.children}
                                subHeaders={etlCategories}
                              />
                            ) : (
                              ""
                            )}
                            {header.text === "OPS" ? (
                              <OpsProductMenus
                                subHeadersData={header.children}
                                subHeaders={opsCategories}
                              />
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        </li>
                      )}
                    {(header.id === "about-us" ||
                      header.id === "home" ||
                      header.id === "blog") && (
                      <NavLink to={header.href} activeClassName="is-active">
                        <span>{header.text}</span>
                        <span className="link-dot-bottom" />
                      </NavLink>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </nav>
            <div>
              <NavLink
                to={"/about#contact"}
                className={"border-menu"}
                style={{ height: 34 }}
              >
                {"Contact Us"}
              </NavLink>
            </div>
          </div>
        </header>
        <header
          id="header"
          role="banner"
          className={["main-header", classes.showGreat960].join(" ")}
        >
          <div className="header-inner">
            <div className="header-logo" style={{ float: "left" }}>
              <Link to="/">
                <img
                  src={IASLogo}
                  alt="Creative Juiz"
                  width="120"
                  height="120"
                />
              </Link>
            </div>
            <div className={classes.showGreat960}>
              <Menu
                onClick={this.toggleDrawer(true)}
                style={{ cursor: "pointer", position: "relative", top: 10 }}
              />
            </div>
            <DrawerMenu
              toggleDrawer={this.toggleDrawer}
              open={this.state.open}
              headerData={headerData}
              location={
                this.props && this.props.location && this.props.location.props
              }
            />
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Header);
