import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const styles = (theme) => ({
  mainGrid: {
    backgroundColor: "#B41E1C",
  },
  bannerText: {
    color: "white",
    textAlign: "center",
    fontFamily: "AvenirLTStd-Roman",
  },
});

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Typography className={classes.bannerText}>
            Our blog page is currently under construction. Please check again
            soon!
          </Typography>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Banner);
