import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  employeeMainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "2.9em",
  },
  employeeImageGrid: {
    display: "flex",
    justifyContent: "center",
  },
  employeeImageStyle: {
    height: 120,
    width: 120,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "grey",
    borderRadius: "4rem",
    objectFit: "contain",
    backgroundColor: "#bfbfbf",
  },
  employeeTextStyle: {
    display: "flex",
    fontfamily: "AvenirLTStd-Roman",
    justifyContent: "center",
    whiteSpace: "nowrap",
    textDecoration: "none",
  },
});

export class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
    };
  }

  render() {
    const { classes, employee } = this.props;

    return (
      <Grid className={classes.employeeMainDiv}>
        <Grid className={classes.employeeImageGrid}>
          <img
            src={employee.imagePath}
            className={classes.employeeImageStyle}
          />
        </Grid>
        <Typography variant="body2" className={classes.employeeTextStyle}>
          {employee.employeeName}
        </Typography>
        <Typography variant="body2" className={classes.employeeTextStyle}>
          {employee.designation}
        </Typography>
        <a
          href={employee.href}
          target="_blank"
          rel="noopener noreferrer"
          // className="product-link-lists"
          className={classes.employeeTextStyle}
        >
          <i
            className={[
              "fa-brands fa-linkedin-in",
              classes.socialMediaiconStyle,
            ].join(" ")}
            aria-hidden="true"
            style={{ color: "#2867B2" }}
          />
        </a>
      </Grid>
    );
  }
}

export default withStyles(styles)(EmployeeDetails);
