import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../layouts/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import carouselData from "../data/homeCarousel.json";
import BackgroundImageBottom from "../assets/images/shape_crescent-bottom.png";
import RediflyTabSection from "../components/pagesComponents/RediflyTabSection";
import AircraftBackground from "../assets/images/main-homebackground-aircraft-image.png";
import TalktoUs from "../components/pagesComponents/TalktoUs";
import DiscoverSection from "../components/pagesComponents/DiscoverSection";
import ProductFeatures from "../components/pagesComponents/ProductFeatures";
import AirOperators from "../components/pagesComponents/AirOperators";
import CustomerReviews from "../components/pagesComponents/CustomerReviews";
import ProductBenefits from "../components/pagesComponents/ProductBenefits";
import LogoCarousel from "../components/pagesComponents/LogoCarousel";
import EtlFeaturesData from "../data/etlFeatures.json";
import EtlBenefitsData from "../data/etlBenefits.json";
import EtlCustomerData from "../data/etlCustomerReviews.json";
import WebinarSection from "../components/pagesComponents/WebinarSection";

const styles = (theme) => ({
  videoMainGrid: {
    // background:
    //   "transparent linear-gradient(180deg, #0278EF 0%, #0278ef14 65%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    height: "72vh",
    padding: "1em 0",
    margin: "0em 0 0 0",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      background: "transparent",
    },
    "@media (min-width: 760px)": {
      height: "auto",
      background: "transparent",
      margin: "4em 0 0 0",
    },
    "@media (min-width: 1200px)": {
      margin: "6.7em 0 0 0",
    },
  },
  carouselBlock: {
    height: "80vh",
    padding: "2em 0 0 5em",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: "1em 0",
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "2.5em 0 0 0",
    },
  },
  carouselImage: {
    background: `url(${AircraftBackground}) -60% 100% no-repeat padding-box`,
    backgroundSize: "contain",
    width: 500,
    height: 500,
    // border: '2px solid #fff',
    maxWidth: "initial",
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: 500,
    },
  },
  carouselContainerLists: {
    height: 600,
    overflow: "hidden",
    position: "relative",
    right: "8%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      margin: "auto",
      right: "5%",
    },
  },
  fingertipTitle: {
    position: "relative",
    fontFamily: "AvenirLTStd-Roman",
    // top: '3em',
    left: "0.7em",
    width: "70%",
    fontSize: 35, // fontSize: 60,
    fontWeight: 100,
    padding: "40px 0 0 15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "center",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      fontSize: 30,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingTop:"7em",
      width: "40%",
      fontSize: 35,
    },
  },
  fingertipTitleSubHeading: {
    position: "relative",
    fontFamily: "AvenirLTStd-Book",
    // top: '3em',
    left: "0.7em",
    color: "grey",
    width: "70%",
    fontSize: 15, // fontSize: 60,
    fontWeight: 10,
    paddingTop: "10px",
    paddingLeft: "30px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      paddingLeft: "0px",
      textAlign: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "55%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "40%",
    },
  },
  fingertipTitle2Grid: {
    display: "flex",
    justifyContent: "left",
    backgroundColor: "#fefbfb",
    paddingBottom: "2em",
  },
  sliderDiv: {
    marginBottom: "-9em",
    [theme.breakpoints.between("xs", "md")]: {
      marginBottom: "0em",
    },
  },
  fingertipTitle2: {
    position: "relative",
    fontFamily: "AvenirLTStd-Roman",
    // top: '3em',
    // right: "0em",
    left: "0.7em",
    width: "60%",
    fontSize: 35, // fontSize: 60,
    fontWeight: 100,
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "left",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      fontSize: 35,
    },
  },
  clientsHeading: {
    position: "relative",
    fontFamily: "S-Core-CoreSansA45Regular",
    // top: '3em',
    // right: "0em",
    left: "0.7em",
    width: "60%",
    fontSize: 35, // fontSize: 60,
    fontWeight: 100,
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "right",
      fontSize: "2rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "55%",
      // marginTop: '1em',
      // background: '#ff000026',
      // top: 20,
      // margin: '1em 0',
      // left: 0,
      // textAlign: 'center',
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "40%",
      // background: 'green',
      // top: '3em',
      // left: 0,
    },
  },
  clientsHeading: {
    position: "relative",
    fontFamily: "S-Core-CoreSansA45Regular",
    // top: '3em',
    // right: "0em",
    left: "0.7em",
    width: "60%",
    fontSize: 35, // fontSize: 60,
    fontWeight: 100,
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "right",
      fontSize: "2rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      // marginTop: '1em',
      // background: '#ff000026',
      // top: 20,
      // margin: '1em 0',
      // left: 0,
      // textAlign: 'center',
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      // background: 'green',
      // top: '3em',
      // left: 0,
    },
  },
  homePageTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "3em 0.5em 0 1em",
    width: "90%",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      padding: 0,
      textAlign: "center",
      fontSize: 25,
    },

    [theme.breakpoints.between("sm", "md")]: {
      padding: "0.5em 0.5em 0 1em",
      width: "100%",
      marginTop: "10%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
    },
  },
  homePageTitledesc: {
    padding: "2em 1em 1em 1.75em",
    fontSize: 20,
    fontWeight: 100,
    width: "90%",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      marginTop: "1em",
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "2em 1em 1em 1em",
      fontSize: 25,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  buttonStyles: {
    textTransform: "inherit",
    width: 400, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  homeButtonItem: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 1em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
  exploreButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    marginLeft: "1.5em",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20, // fontSize: 30,
    },
  },
  demoButtonGrid: {
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  demoButtonStyle: {
    textTransform: "inherit",
    fontFamily: " AvenirLTStd-Roman",
    width: 200, // width: 200,
    fontSize: 20, // fontSize: 30,
    background: "#fff",
    color: "#B41E1C",
    border: "1px solid #B41E1C",
    fontWeight: "none",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
      margin: "1em 0 0 0em",
      fontSize: 15, // fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 200,
      margin: "2em 0 0 0em",
    },
  },
  productTabLabel: {
    textTransform: "capitalize",
    // fontSize: '15pt',
    fontSize: 28, //fontSize: 40,
    fontFamily: "AvenirLTStd-Roman",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1em",
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  productTabSelected: {
    color: "#000 !important",
  },
  backgroundDesign1: {
    // background: `transparent url(${BackgroundImageTop}) 100% 114% no-repeat padding-box`,
    padding: "0em",
  },
  backgroundDesign2: {
    background: `transparent url(${BackgroundImageBottom}) -40% 106% no-repeat padding-box`,
  },
  exploreProductLink: {
    textAlign: "left",
    padding: "1em 1em 1em 1.2em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em",
    },
  },
  tabContainerBlock: {
    padding: "3em 24px 24px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 1em 0 !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: '#ff000026'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'green'
    },
  },
  buttonContainer: {
    // justifyContent: "space-around",
    marginTop: "1em",
    [theme.breakpoints.down("xs")]: {
      // justifyContent: "space-around",
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // justifyContent: "space-around",
      // background: 'red'
    },
  },
  productTabsContainer: {
    padding: "0 24px 0 52px",
    marginTop: "5em",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: "2em",
    },
  },
  indexCarousrlBlockContainer: {
    padding: "0em 1.5em 0 1.8em",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0em 1.5em 0 1.8em",
    },
  },
  productTabsDesc: {
    padding: "1em",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 24, // fontSize: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 25,
    },
  },
  tabContainerTabs: {
    marginLeft: "0.5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  productImage: {
    minHeight: 200,
    minWidth: 200,
    maxWidth: "initial",
    // height: 600,
    // width: 600
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      marginTop: "1em",
    },
  },
  productImageCoreCommand: {
    height: 560,
    width: 890,
    position: "relative",
    left: -165,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 400,
      height: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productImageOtherThanCoreCommand: {
    height: 550,
    width: 650,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 400,
      height: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  exploreAnchorLink: {
    textDecoration: "none",
    color: "#B41E1C",
    fontFamily: " AvenirLTStd-Roman",
    fontSize: 26, //fontSize: '30px',
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
    "@media (min-width: 375px)": {
      fontSize: 18,
    },
    "@media (min-width: 800px)": {
      fontSize: 26,
    },
    "@media (min-width: 1280px)": {
      fontSize: 30,
    },
  },
});

class ETLPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this.loadCarousel();
  }

  loadCarousel = () => {
    window.jQuery(".my-flipster").slick({
      autoplay: true,
      autoplaySpeed: 10000,
      infinite: true,
      vertical: true,
      verticalSwiping: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  };

  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Demo Request");
    const email = "sales@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Layout>
        <div>
          <Grid container className={classes.indexCarousrlBlockContainer}>
            <Grid
              item
              lg={7}
              md={7}
              sm={7}
              xs={12}
              className={classes.backgroundDesign1}
              // data-aos="zoom-in"
            >
              <Typography variant="h1" className={classes.homePageTitle}>
                The Electronic Tech Log for aviation's digital future
              </Typography>
              <Typography variant="body2" className={classes.homePageTitledesc}>
                Our bespoke Electronic Tech Log solution enables air operators
                to become fully connected data driven and efficient providers of
                aviation services.
              </Typography>
              <Grid container className={classes.buttonContainer}>
                <Grid
                  item
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  className={classes.homeButtonItem}
                >
                  <Button
                    variant="contained"
                    href="https://calendly.com/redifly/discoverycall"
                    target="_blank"
                    className={[
                      classes.buttonStyles,
                      classes.exploreButtonStyle,
                    ].join(" ")}
                  >
                    Schedule a Discovery Call
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={10}
                  xs={12}
                  className={classes.demoButtonGrid}
                >
                  <Button
                    variant="contained"
                    onClick={this.handleEmailClick}
                    className={classes.demoButtonStyle}
                  >
                    Book a Demo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className={classes.videoMainGrid}
              // data-aos="zoom-in-down"
            >
              <div class="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/_0tavthjNXw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ borderRadius: "20px" }}
                ></iframe>
              </div>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // data-aos="fade-up"
              // data-aos-duration="3000"
            >
              <Typography variant="h2" className={classes.fingertipTitle}>
                Take control of your data with the REDiFly Electronic Tech Log
              </Typography>
              <Typography className={classes.fingertipTitleSubHeading}>
                We provide a modular and configurable electronic tech log that
                provides real-time visibility of airworthiness across your
                entire fleet.
              </Typography>
            </Grid>
            <ProductFeatures featureData={EtlFeaturesData} />
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // data-aos="fade-up"
              // data-aos-duration="3000"
              className={classes.fingertipTitle2Grid}
            >
              <Typography variant="h2" className={classes.fingertipTitle2}>
                Make the switch to paperless with our Electronic Tech Log suite
              </Typography>
            </Grid>
            <RediflyTabSection />
          </Grid>
          <AirOperators />
          <ProductBenefits benefitsData={EtlBenefitsData} />
          <LogoCarousel />
          <CustomerReviews customerReviewData={EtlCustomerData} />
          <WebinarSection/>
          <TalktoUs />
          <DiscoverSection />
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(ETLPage);
