import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../layouts/index";
import Typography from "@material-ui/core/Typography";
import ArticleImage from "../assets/images/ArticleImage.png";
import { useLocation } from "react-router-dom";
import DiscoverSection from "../components/pagesComponents/DiscoverSection";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";


const styles = (theme) => ({
  articlePageTitle: {
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "0em 0.5em 0 1em",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 35,
    // [theme.breakpoints.between("sm", "md")]: {
    //   padding: "0.5em 0.5em 0 1em",
    //   width: "100%",
    //   marginTop: "10%",
    //   fontSize: 30,
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 40,
    // },
  },
  articleMainGrid: {
    display: "flex",
    flexDirection: "column",
  },
  mainImageGrid: {
    display: "flex",
    justifyContent: "center",
  },
  subTextStyle: {
    padding: "1em",
    fontSize: 12,
    color: "red",
  },
  subHeaderGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "2.7em 0 0 0",
  },
  mainCenterGrid: {
    display: "flex",
    justifyContent: "center",
  },
  headingTextMainGrid: {
    width: "65%",
  },
  keyPointsMainGrid: {
    backgroundColor: "#ffe6e6",
    borderRadius: "1em",
    padding: "0.8em",
    margin: "1em 0 0 0em",
    width: "60%",
  },
  articleDescGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "2em 0 2em 0",
  },
  articleDescText: {
    width: "60%",
  },
  authorGrid: {
    padding: "1em 0 2em 0",
  },
  authorImageGrid: {
    display: "flex",
    justifyContent: "right",
  },
  authorDescTextGrid: {
    padding: "0.5em 0 0 1em",
    width: "90%",
  },
  socialMainGrid: {
    display: "flex",
    flexDirection: "column",
    padding: "1em 0 4em 0",
    alignItems: "center",
  },
  socialIconsGrid: {
    padding: "1em 0 0 0",
    margin:"10px",
  },
  socialMediaiconStyle: {
    fontSize: "2em",
    position: "relative",
  },
  discoverMainGrid: {
    display: "flex",
    flexDirection: "column",
    padding: "1em 0 0em 0",
  },
  tileGrid: {
    display: "flex",
    margin: "auto",
    maxWidth: "17rem",
    height: "20rem",
    backgroundColor: "#fefbfb",
    borderRadius: "1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.4em",
    boxShadow: "5px 5px 19px 0px rgba(255, 230, 230)",
    cursor: "pointer",
    "&:hover": {
      marginTop: "-0.3rem",
      marginLeft: "-0.1rem",
    },
  },
  imageStyle: {
    display: "flex",
    justifyContent: "center",
    objectFit: "contain",
    height: "50%",
    width: "100%",
  },
  tileTitleStyle: {
    fontSize: 20,
    padding: "0.5em 0 0.5rem 0",
  },
  readTextGrid: {
    display: "flex",
    justifyContent: "center",
  },
  subTextStyle: {
    padding: "3px",
    fontSize: 12,
    color: "grey",
  },
  linkTextStyle: {
    fontSize: 16,
    color: "#fa1414",
    paddingTop: "1rem",
  },
});

export class ArticlePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    // InitCookies();
    this.loadCarousel();
  }

  loadCarousel = () => {
    // window.jQuery('.my-flipster').flipster({
    //   start: 'center',
    //   loop: true,
    //   autoplay: 9000,
    //   style: 'coverflow',
    //   spacing: -0.6,
    // })
    window.jQuery(".my-flipster").slick({
      autoplay: true,
      autoplaySpeed: 10000,
      infinite: true,
      vertical: true,
      verticalSwiping: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  };
  render() {
    const { classes  } = this.props;
    const { value } = this.state;
    const blogData = this.props.location.state.blog;
    const baseUrl = "https://localhost:44361";
    const authorImage = `${baseUrl}${blogData.AuthorImage}`;
    const thumbnailUrl = `${baseUrl}${blogData.ThumbnailUrl}`;
   
    return (
      <div>
        <Layout>
          <div>
            <Grid className={classes.articleMainGrid}>
              <Grid container className={classes.mainImageGrid}>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    style={{ width: "100%", alignContent: "center" }}
                    src={thumbnailUrl}
                  ></img>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              <Grid className={classes.subHeaderGrid}>
                <Typography className={classes.subTextStyle}>
                  {blogData.Author} -
                </Typography>
                <Typography className={classes.subTextStyle}>
                  4 min read -
                </Typography>
                <Typography className={classes.subTextStyle}>
                  {blogData.PublishDate}
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.mainCenterGrid}>
              <Grid className={classes.headingTextMainGrid}>
                <Typography variant="h1" className={classes.articlePageTitle}>
                  {blogData.Title}
                </Typography>
              </Grid>
            </div>
            <div className={classes.mainCenterGrid}>
              <Grid className={classes.keyPointsMainGrid}>
                <Typography variant="h6" style={{ color: "red" }}>
                  Key Takeaway
                </Typography>
                <Typography variant="body2">{blogData.KeyTakeAways}</Typography>
              </Grid>
            </div>
            <Grid container className={classes.articleDescGrid}>
              <Typography variant="body2" className={classes.articleDescText}>
                {ReactHtmlParser(blogData.MainContentMarkup)}
              </Typography>
              <Typography variant="body2" className={classes.articleDescText}>
                {ReactHtmlParser(blogData.Conclusion)}
              </Typography>
            </Grid>
            <Grid container className={classes.authorGrid}>
              <Grid item xs={4} className={classes.authorImageGrid}>
                <div className="customer-image-bubble">
                  <img style={{ width: "90%" }} src={authorImage}></img>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  className={classes.authorDescTextGrid}
                >
                  {blogData.AuthorDetails}
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid className={classes.mainCenterGrid}>
              <Grid className={classes.socialMainGrid}>
                <Typography variant="h6">Follow Us!</Typography>
                <Grid container className={classes.socialIconsGrid}>
                  <Grid item xs={4} className={classes.mainCenterGrid}>
                    <a
                      href="https://www.linkedin.com/company/redifly/"
                      target="_blank"
                    >
                      <i
                        className={[
                          "fa-brands fa-linkedin-in",
                          classes.socialMediaiconStyle,
                        ].join(" ")}
                        aria-hidden="true"
                        style={{ color: "#2867B2" }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={4} className={classes.mainCenterGrid}>
                    <a
                      href="https://www.youtube.com/@redifly5063"
                      target="_blank"
                    >
                      <i
                        className={[
                          "fa-brands fa-youtube",
                          classes.socialMediaiconStyle,
                        ].join(" ")}
                        aria-hidden="true"
                        style={{ color: "#FF0000" }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={4} className={classes.mainCenterGrid}>
                    <a
                      href="https://www.instagram.com/redifly_/?hl=en"
                      target="_blank"
                    >
                      <img
                        width="40"
                        height="40"
                        alt="insta-logo"
                        src="/images/instalogo.png"
                        className={classes.socialMediaiconStyle}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DiscoverSection />
          </div>
        </Layout>
      </div>
    );
  }
}

export default withStyles(styles)(ArticlePage);
