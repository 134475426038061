import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { NavLink } from "react-router-dom";
import winopsAppData from "../../data/winopsApps.json";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import moduleListPDF from "../../assets/pdfs/Flight Ops Full Module List.pdf";

const styles = (theme) => ({
  productTabsContainer: {
    padding: "0 24px 0 40px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  backgroundDesign2: {
    backgroundColor: "#fefbfb",
    // background: `transparent url(${BackgroundImageBottom}) -20% 111% no-repeat padding-box`,
  },
  tabContainerTabs: {
    marginLeft: "0.5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  productTabLabel: {
    textTransform: "capitalize",
    fontSize: 25,
    fontFamily: "AvenirLTStd-Roman",
    alignItems: "flex-start",
    marginRight: "2em",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1em",
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  productTabSelected: {
    color: "#000 !important",
  },
  tabContainerBlock: {
    padding: "1em 24px 24px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 1em 0 !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: '#ff000026'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'green'
    },
  },
  productImage: {
    minHeight: 200,
    minWidth: 200,
    maxWidth: "initial",
    // height: 600,
    // width: 600
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      marginTop: "1em",
    },
  },
  productImageCoreCommand: {
    height: 500,
    width: 800,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 400,
      height: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productImageOtherThanCoreCommand: {
    height: 400,
    width: 600,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 300,
      height: 300,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productTabsDesc: {
    padding: "1em",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 22, // fontSize: 28,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 25,
    },
  },
  exploreProductLink: {
    textAlign: "left",
    padding: "1em 1em 1em 1.5em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  exploreAnchorLink: {
    textDecoration: "none",
    color: "#B41E1C",
    fontFamily: " AvenirLTStd-Roman",
    fontSize: 26, //fontSize: '30px',
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
    "@media (min-width: 375px)": {
      fontSize: 18,
    },
    "@media (min-width: 800px)": {
      fontSize: 26,
    },
    "@media (min-width: 1280px)": {
      fontSize: 30,
    },
  },
  moduleListGrid: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "3em",
    [theme.breakpoints.down("xs")]: {},
  },
  buttonStyles: {
    textTransform: "inherit",
    width: 290, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  moduleListButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    marginLeft: "1.5em",
    fontFamily: "S-Core-CoreSansA65Bold",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20, // fontSize: 30,
    },
  },
});

export class WinopsApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDownloadClick () {
    const pdfFile = moduleListPDF;
    const anchor = document.createElement("a");
    anchor.href = pdfFile;
    anchor.download = "Flight OPs Module List.pdf";
    anchor.click();
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={[
          classes.productTabsContainer,
          classes.backgroundDesign2,
        ].join(" ")}
        // data-aos="fade-down"
        // data-aos-easing="ease-in-sine"
        // data-aos-anchor-placement="top-bottom"
      >
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          variant="scrollable"
          classes={{ indicator: "product-tabs-indicator" }}
          className={classes.tabContainerTabs}
        >
          {winopsAppData &&
            winopsAppData.map((item, index) => {
              return (
                <Tab
                  key={index}
                  classes={{
                    wrapper: classes.productTabLabel,
                    selected: classes.productTabSelected,
                  }}
                  label={item.name}
                  className="cool-link"
                />
              );
            })}
        </Tabs>
        {winopsAppData &&
          winopsAppData.map((item, index) => {
            return (
              value === index && (
                <Typography
                  component="div"
                  key={index}
                  className={classes.tabContainerBlock}
                >
                  <Grid container className="product-tab-container">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <img
                        alt="product"
                        className={[
                          classes.productImage,
                          item.id === "core" || item.id === "command"
                            ? classes.productImageCoreCommand
                            : classes.productImageOtherThanCoreCommand,
                        ].join(" ")}
                        src={item.imagePath}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span className="product-content-border-top" />
                      <Typography
                        variant="body1"
                        className={classes.productTabsDesc}
                      >
                        {item.description}
                      </Typography>
                      <p className={classes.exploreProductLink}>
                        {/* <NavLink
                          to={item.redirectPath}
                          className={classes.exploreAnchorLink}
                        >
                          <span>
                            Explore REDiFly {item.name}{" "}
                            <ArrowForward
                              style={{ position: "relative", top: 0 }}
                            />
                          </span>
                        </NavLink> */}
                      </p>
                    </Grid>
                  </Grid>
                </Typography>
              )
            );
          })}
        <Grid className={classes.moduleListGrid}>
          <Button
            variant="contained"
            onClick={this.handleDownloadClick}
            className={[
              classes.buttonStyles,
              classes.moduleListButtonStyle,
            ].join(" ")}
          >
            View Full Module List
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(WinopsApps);
