import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IFTestimonial from "../../assets/images/etl-page/IFTestimonial.png";
import IFLogo from "../../assets/images/etl-page/IFLogo.png";

const styles = (theme) => ({
  mainGrid: {
    paddingTop: "3em",
    paddingBottom: "5em",
    backgroundColor: "#fefbfb",
  },
  mainHeadingGrid: {
    paddingLeft: "3em",
    paddingBottom: "3em",
  },
  mainHeading: {
    fontSize: 30,
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  testimonialContentGrid: {
    padding: "2em 0 0 8em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0.5em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      padding: "0 0 0 1em",
    },
  },
  bubbleGrid: {
    padding: "1em",
    zIndex: 2,
  },
  custImageGrid: {
    padding: "1.5em 0 0 0",
  },
  custDetailsGrid: {
    display: "flex",
    flexDirection: "column",
  },
  custHeading: {
    display: "flex",
    justifyContent: "left",
    fontSize: 20,
    color: "grey",
    fontFamily: "AvenirLTStd-Roman",
  },
  custDesignation: {
    display: "flex",
    justifyContent: "left",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 16,
    color: "grey",
  },
  custReview: {
    display: "flex",
    justifyContent: "left",
    padding: "1.3em 0 0 0",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 16,
    color: "black",
    textAlign: "left",
  },
  ifLogoGrid: {
    [theme.breakpoints.down("xs")]: {
      padding: "0em 0 0 4em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      padding: "0em 0 0 4em",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0em 0 0 0em",
    },
  },
  customerImage: {
    width: "100%", // width: 81,
    height: "100%", // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: "75%",
      height: "75%",
    },
  },
  logoImage: {
    width: 300, // width: 81,
    height: 300, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 200, // width: 81,
      height: 150,
    },
  },
});

export class CustomerReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }
  render() {
    const { classes, customerReviewData } = this.props;

    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Grid className={classes.mainHeadingGrid}>
            <Typography variant="h2" className={classes.mainHeading}>
              {customerReviewData.title}
            </Typography>
          </Grid>
          {customerReviewData &&
            customerReviewData.data &&
            customerReviewData.data.map((item, index) => {
              return (
                <Grid
                  container
                  className={classes.testimonialContentGrid}
                  key={index}
                >
                  <Grid item xs={12} sm={9} lg={8}>
                    <div className="bubble">
                      <Grid container className={classes.bubbleGrid}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          lg={4}
                          className={classes.custImageGrid}
                        >
                          <div className="customer-image-bubble">
                            <img
                              alt="iflogo"
                              src={item.imagePath}
                              className={classes.customerImage}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          lg={8}
                          className={classes.custDetailsGrid}
                        >
                          <Typography
                            variant="h6"
                            className={classes.custHeading}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.custDesignation}
                          >
                            {item.designation}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.custReview}
                          >
                            {item.review}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    lg={4}
                    className={classes.ifLogoGrid}
                  >
                    <img
                      alt="iflogo"
                      src={item.companyLogo}
                      className={classes.logoImage}
                    />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CustomerReviews);
