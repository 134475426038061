import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../layouts/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BackgroundImage from "../assets/images/img_customer-portal-hero@2x.png";
import SignInForm from "../components/pagesComponents/SignInForm";
import { Helmet } from "react-helmet-async";

const styles = (theme) => ({
  carouselBlock: {
    background:
      "transparent linear-gradient(180deg, #0278EF 0%, #0055AC66 65%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    height: "100vh",
    padding: "2em 0 4em 0",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: "2em 0",
      background: "transparent",
    },
  },
  carouselBlockBackgroundImg: {
    position: "relative",
    right: "15%",
    height: 850,
    width: 650,
    bottom: "10%",
    [theme.breakpoints.down("xs")]: {
      right: 0,
    },
  },
  CustomerPortalLinkTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "0 0.5em",
    margin: "0 0 1em 0",
    fontFamily: "S-Core-CoreSansA65Bold",
    fontSize: "2rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0.5em 0",
      textAlign: "center",
    },
  },
  CustomerPortalLinkTitleDesc: {
    paddingLeft: "1em",
    paddingRight: "2em",
    fontSize: "0.9rem",
    fontWeight: 100,
    fontFamily: "S-Core-CoreSansA35Light",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  CustomerPortalLinkworkDesc: {
    fontSize: "0.9rem",
    fontWeight: 100,
    padding: "10px 30px 10px 0",
    // textAlign: 'justify',
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 10px",
      textAlign: "justify",
    },
  },
  CustomerPortalLinkworkDescOpening: {
    fontSize: "0.9rem",
    fontWeight: 100,
    padding: "10px 30px 10px 0",
    // textAlign: 'justify',
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 10px",
      // textAlign: 'justify'
    },
  },
  buttonStyles: {
    textTransform: "capitalize",
    width: 200,
    fontSize: "0.9rem",
  },

  exploreButtonStyle: {
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
  },
  DemoButtonStyle: {
    background: "#B41E1C",
    color: "#fff",
    // border: '1px solid #B41E1C',
    fontFamily: "AvenirLTStd-Roman",
    fontWeight: "none",
    marginLeft: "2em",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      // width: '100%',
    },
  },

  CustomerPortalTitle: {
    // textAlign: 'left',
    // letterSpacing: '0.66px',
    color: "#223140",
    marginTop: "10%",
    padding: "0 1em",
    width: "85%",
    fontFamily: "S-Core-CoreSansA55Medium",
    // fontSize: '2.5rem',
    fontSize: 64, // fontSize: 69,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      padding: 0,
      textAlign: "center",
      fontSize: "2.5rem",
    },
  },
  CustomerPortalTitledesc: {
    padding: "2em 1em 1em 3em",
    // fontSize: '0.9rem',
    fontSize: 25, // fontSize: 28,
    fontWeight: 100,
    width: "85%",
    fontFamily: "S-Core-CoreSansA35Light",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      fontSize: "1rem",
      marginTop: "1em",
      textAlign: "center",
    },
  },
  buttonContainer: {
    // justifyContent: 'space-around',
    marginTop: "2em",
    marginLeft: "1em",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      marginLeft: 0,
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // justifyContent: 'space-around',
      // background: 'red'
    },
  },
});

class CustomerPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>REDiFly FAQS</title>
          <meta
            name="description"
            contents="Find more information about commonly asked questions about REDiFly products."
          />
          <link rel="canonical" href="/faqs" />
        </Helmet>
        <div className="container">
          <Grid container style={{ margin: "2em 0" }}>
            <Grid
              item
              lg={7}
              md={7}
              sm={7}
              xs={12}
              // data-aos="zoom-in"
              className={classes.backgroundDesign1}
            >
              <Typography variant="h4" className={classes.CustomerPortalTitle}>
                Coming Soon…
              </Typography>
              <Typography
                variant="body2"
                className={classes.CustomerPortalTitledesc}
              >
                Your gateway to all of our products & services.
              </Typography>
              <SignInForm />
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className={classes.carouselBlock}
              // data-aos="zoom-in-down"
            >
              <div>
                <img
                  src={BackgroundImage}
                  alt="banner"
                  className={classes.carouselBlockBackgroundImg}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(CustomerPortal);
