import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../layouts/index";
// import Hero from '../components/hero/Hero';
// import HeroIllustration from '../components/hero/HeroIllustration';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { NavLink } from "react-router-dom";
import productTabsData from "../../data/productstab.json";
import carouselData from "../../data/homeCarousel.json";
// import classes from '*.module.css';
import ArrowForward from "@material-ui/icons/ArrowForward";
// import AboutUsSection from "./AboutUsSection";
import BackgroundImageTop from "../../assets/images/shape_crescent-top.png";
import BackgroundImageBottom from "../../assets/images/shape_crescent-bottom.png";

const styles = (theme) => ({
  productTabsContainer: {
    padding: "0 24px 0 40px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  backgroundDesign2: {
    // background: `transparent url(${BackgroundImageBottom}) -20% 111% no-repeat padding-box`,
    backgroundColor: "#fefbfb",
  },
  tabContainerTabs: {
    marginLeft: "0.5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  productTabLabel: {
    textTransform: "capitalize",
    fontSize: 25,
    fontFamily: "AvenirLTStd-Roman",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1em",
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: '#ff000026'
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'green'
      fontSize: 28,
    },
  },
  productTabSelected: {
    color: "#000 !important",
  },
  tabContainerBlock: {
    padding: "3em 24px 24px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 1em 0 !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: '#ff000026'
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'green'
      fontSize: 35,
    },
  },
  productImage: {
    minHeight: 200,
    minWidth: 200,
    maxWidth: "initial",
    // height: 600,
    // width: 600
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      marginTop: "1em",
    },
  },
  productImageCoreCommand: {
    height: 500,
    width: 700,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 400,
      height: 300,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productImageOtherThanCoreCommand: {
    height: 500,
    width: 600,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 360,
      height:400,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productTabsDesc: {
    padding: "1em",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 23, // fontSize: 28,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 25,
    },
  },
  exploreProductLink: {
    textAlign: "left",
    padding: "1em 1em 1em 1.5em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em",
    },
  },
  exploreAnchorLink: {
    textDecoration: "none",
    color: "#B41E1C",
    fontFamily: " AvenirLTStd-Roman",
    fontSize: 26, //fontSize: '30px',
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
    "@media (min-width: 375px)": {
      fontSize: 18,
    },
    "@media (min-width: 800px)": {
      fontSize: 26,
    },
    "@media (min-width: 1280px)": {
      fontSize: 30,
    },
  },
});

export class RediflyTabSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={[
          classes.productTabsContainer,
          classes.backgroundDesign2,
        ].join(" ")}
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"
      >
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          variant="scrollable"
          classes={{ indicator: "product-tabs-indicator" }}
          className={classes.tabContainerTabs}
        >
          {productTabsData &&
            productTabsData.map((item, index) => {
              return (
                <Tab
                  key={index}
                  classes={{
                    wrapper: classes.productTabLabel,
                    selected: classes.productTabSelected,
                  }}
                  label={item.name}
                  className="cool-link"
                />
              );
            })}
        </Tabs>
        {productTabsData &&
          productTabsData.map((item, index) => {
            return (
              value === index && (
                <Typography
                  component="div"
                  key={index}
                  className={classes.tabContainerBlock}
                  style={{ padding: "3em 24px 24px 24px" }}
                >
                  <Grid container className="product-tab-container">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <img
                        alt="product"
                        className={[
                          classes.productImage,
                          item.id === "core" || item.id === "command"
                            ? classes.productImageCoreCommand
                            : classes.productImageOtherThanCoreCommand,
                        ].join(" ")}
                        src={item.imagePath}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span className="product-content-border-top" />
                      <Typography
                        variant="body1"
                        className={classes.productTabsDesc}
                      >
                        {item.description}
                      </Typography>
                      <p className={classes.exploreProductLink}>
                        <NavLink
                          to={item.redirectPath}
                          className={classes.exploreAnchorLink}
                        >
                          <span>
                            Explore REDiFly {item.name}{" "}
                            <ArrowForward
                              style={{ position: "relative", top: 0 }}
                            />
                          </span>
                        </NavLink>
                      </p>
                    </Grid>
                  </Grid>
                </Typography>
              )
            );
          })}
      </Grid>
    );
  }
}

export default withStyles(styles)(RediflyTabSection);
