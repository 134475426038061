import React from "react";
import ReactDOM from "react-dom";
import initialize from "./assets/js/main.js";
import './assets/css/fonts-new.css';
import "./assets/css/cookies.css";
import "./assets/css/fonts-new.css";
import "./assets/css/style.css";
import "./assets/css/customize.css";
import "./assets/css/ias.css";
import "./assets/css/aos.css";
import "./assets/css/header-menu.css";
import "./web.config";

import Routes from "./routes";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(<Routes />, document.getElementById("root"));
registerServiceWorker();
initialize();
