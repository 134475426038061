import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../layouts/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import carouselData from "../data/homeCarousel.json";
import FutureProofSection from "../components/pagesComponents/FutureProofSection";
import BackgroundImageBottom from "../assets/images/shape_crescent-bottom.png";
import RediflyTabSection from "../components/pagesComponents/RediflyTabSection";
import WinopsTabSection from "../components/pagesComponents/WinopsTabSection";
import AircraftBackground from "../assets/images/main-homebackground-aircraft-image.png";
import TalktoUs from "../components/pagesComponents/TalktoUs";
import DiscoverSection from "../components/pagesComponents/DiscoverSection";
import LogoCarousel from "../components/pagesComponents/LogoCarousel";
import WebinarSection from "../components/pagesComponents/WebinarSection";

const styles = (theme) => ({
  carouselBlock: {
    height: "80vh",
    padding: "2em 0 0 5em",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: "1em 0",
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "2.5em 0 0 0",
    },
  },
  carouselImage: {
    background: `url(${AircraftBackground}) -60% 100% no-repeat padding-box`,
    backgroundSize: "contain",
    width: 500,
    height: 500,
    // right:"10%",
    // border: '2px solid #fff',
    maxWidth: "initial",
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: 500,
    },
    [theme.breakpoints.between("sm","md")]: {
      width: 400,
      height: 400,
    },
  },
  carouselContainerLists: {
    height: 600,
    overflow: "hidden",
    position: "relative",
    right: "8%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      margin: "auto",
      right: "5%",
    },
  },
  fingertipTitleGrid: {
    width: "100%",
    backgroundColor: "#fefbfb",
    paddingBottom: "1em",
  },
  fingertipTitle: {
    position: "relative",
    fontFamily: "AvenirLTStd-Roman",
    left: "0.7em",
    width: "70%",
    fontSize: 35,
    fontWeight: 100,
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "center",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      fontSize: 35,
    },
  },
  fingertipTitle2Grid: {
    display: "flex",
    justifyContent: "left",
    backgroundColor: "#fefbfb",
    paddingBottom: "1em",
  },
  sliderDiv: {
    marginBottom: "-9em",
    [theme.breakpoints.between("xs", "md")]: {
      marginBottom: "0em",
    },
  },
  fingertipTitle2: {
    position: "relative",
    fontFamily: "AvenirLTStd-Roman",
    left: "0.7em",
    width: "60%",
    fontSize: 35,
    fontWeight: 100,
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "left",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      fontSize: 35,
    },
  },
  mainOurClientsGrid: {
    backgroundColor: "#fefbfb",
  },
  clientsHeadingGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "1.5em 0 0 0",
  },
  clientsHeading: {
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 40,
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 40,
    },
  },
  homePageTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "3em 0.5em 0 1em",
    width: "90%",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      padding: 0,
      textAlign: "center",
      fontSize: 25,
    },

    [theme.breakpoints.between("sm", "md")]: {
      padding: "0.5em 0.5em 0 1em",
      width: "100%",
      marginTop: "10%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
    },
  },
  homePageTitledesc: {
    padding: "2em 1em 1em 1.75em",
    fontSize: 20,
    fontWeight: 100,
    width: "90%",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      marginTop: "1em",
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "2em 1em 1em 1em",
      fontSize: 25,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.up("md")]: {},
  },
  buttonStyles: {
    textTransform: "inherit",
    width: 400, // width: 200,
    fontSize: 20, // fontSize: 30,
    // fontSize: '0.9rem',
  },
  homeButtonItem: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 1em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
  exploreButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    marginLeft: "1.5em",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20, // fontSize: 30,
    },
  },
  demoButtonGrid: {
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  demoButtonStyle: {
    textTransform: "inherit",
    width: 200, // width: 200,
    fontSize: 20, // fontSize: 30,
    fontFamily: "AvenirLTStd-Roman",
    background: "#fff",
    color: "#B41E1C",
    border: "1px solid #B41E1C",
    fontWeight: "none",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
      margin: "1em 0 0 0em",
      fontSize: 15, // fontSize: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 200,
      margin: "2em 0 0 0em",
    },
  },
  productTabLabel: {
    textTransform: "capitalize",
    // fontSize: '15pt',
    fontSize: 32, //fontSize: 40,
    fontFamily: "AvenirLTStd-Roman",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
      paddingRight: "1em",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  productTabSelected: {
    color: "#000 !important",
  },
  backgroundDesign1: {
    // background: `transparent url(${BackgroundImageTop}) 100% 114% no-repeat padding-box`,
    padding: "0.4em",
  },
  backgroundDesign2: {
    background: `transparent url(${BackgroundImageBottom}) -40% 106% no-repeat padding-box`,
  },
  exploreProductLink: {
    textAlign: "left",
    padding: "1em 1em 1em 1.2em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em",
    },
  },
  tabContainerBlock: {
    padding: "3em 24px 24px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 1em 0 !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: '#ff000026'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'green'
    },
  },
  buttonContainer: {
    // justifyContent: "space-around",
    marginTop: "1em",
    [theme.breakpoints.down("xs")]: {
      // justifyContent: "space-around",
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // justifyContent: "space-around",
      // background: 'red'
    },
  },
  productTabsContainer: {
    padding: "0 24px 0 52px",
    marginTop: "5em",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: "2em",
    },
  },
  indexCarousrlBlockContainer: {
    marginTop: "0em",
    padding: "-1px 0 0 0em",
    // [theme.breakpoints.between("sm")]: {
    paddingBottom: "0.1rem",
    // },
  },
  productTabsDesc: {
    padding: "1em",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 24, // fontSize: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  tabContainerTabs: {
    marginLeft: "0.5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  productImage: {
    minHeight: 200,
    minWidth: 200,
    maxWidth: "initial",
    // height: 600,
    // width: 600
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      marginTop: "1em",
    },
  },
  productImageCoreCommand: {
    height: 560,
    width: 890,
    position: "relative",
    left: -165,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 400,
      height: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  productImageOtherThanCoreCommand: {
    height: 550,
    width: 650,
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: "auto",
      maxWidth: "inherit",
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "100%",
      width: 400,
      height: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "initial",
    },
  },
  exploreAnchorLink: {
    textDecoration: "none",
    color: "#B41E1C",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 26, //fontSize: '30px',
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
    "@media (min-width: 375px)": {
      fontSize: 22,
    },
    "@media (min-width: 800px)": {
      fontSize: 30,
    },
    "@media (min-width: 1280px)": {
      fontSize: 35,
    },
  },
});

// function TabContainer({ children, dir }) {
//   return (
//     <Typography component="div" dir={dir} style={{ padding: '3em 24px 24px 24px' }}>
//       <Grid container className="product-tab-container">
//         <Grid item lg={6} md={6} sm={6} xs={12}>
//           <img style={{minHeight: 200, minWidth: 200, height: 300, width: 500 }} src={children.imagePath} />
//         </Grid>
//         <Grid item lg={6} md={6} sm={6} xs={12} style={{display: 'flex', alignSelf: 'center', flexDirection: 'column'}}>
//           <span className='product-content-border-top' />
//           <Typography variant="body1" style={{padding: '1em '}}>{children.description}</Typography>
//           <p>
//             <NavLink to={children.redirectPath} style={{textDecoration: 'none', color: '#B41E1C'}}>
//               <span>Explore REDiFly {children.name} <ArrowForward style={{position: 'relative', top: 10}}/></span>
//             </NavLink>
//           </p>
//         </Grid>
//       </Grid>
//     </Typography>
//   );
// }

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    // InitCookies();
    this.loadCarousel();
  }

  loadCarousel = () => {
    // window.jQuery('.my-flipster').flipster({
    //   start: 'center',
    //   loop: true,
    //   autoplay: 9000,
    //   style: 'coverflow',
    //   spacing: -0.6,
    // })
    window.jQuery(".my-flipster").slick({
      autoplay: true,
      autoplaySpeed: 10000,
      infinite: true,
      vertical: true,
      verticalSwiping: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  };
  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Demo Request");
    const email = "sales@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div>
        <Layout>
          <div>
            <Grid container className={classes.indexCarousrlBlockContainer}>
              <Grid
                item
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes.backgroundDesign1}
                // data-aos="zoom-in"
              >
                <Typography variant="h1" className={classes.homePageTitle}>
                  Integrated aviation software for aviation's digital future 
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.homePageTitledesc}
                >
                  We provide bespoke Electronic Tech Log and Flight Operations
                  solutions to enable operators to become fully connected and
                  data driven providers of aviation services.
                </Typography>
                <Grid container className={classes.buttonContainer}>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className={classes.homeButtonItem}
                  >
                    <Button
                      variant="contained"
                      href="https://calendly.com/redifly/discoverycall"
                      target="_blank"
                      className={[
                        classes.buttonStyles,
                        classes.exploreButtonStyle,
                      ].join(" ")}
                    >
                      Schedule a Discovery Call
                    </Button>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={10}
                    xs={12}
                    className={classes.demoButtonGrid}
                  >
                    <Button
                      variant="contained"
                      onClick={this.handleEmailClick}
                      className={classes.demoButtonStyle}
                    >
                      Book a Demo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes.carouselBlock}
                // data-aos="zoom-in-down"
              >
                {/* <div>
                    <div id="coverflow">
                      <img src="http://placehold.it/350x350" />
                      <img src="http://placehold.it/350x350/E8117F/FFFFFF" />
                      <img src="http://placehold.it/350x350" />
                      <img src="http://placehold.it/350x350/E8117F/FFFFFF" />
                      <img src="http://placehold.it/350x350" />
                    </div>
                    </div>
                  <div id="coverflow-slider"></div> */}
                <div
                  className={[
                    "my-flipster",
                    classes.carouselContainerLists,
                  ].join(" ")}
                >
                  {/* <ul>
                  {carouselData && carouselData.map((item, index) => {
                    return (<li><img style={{width: 400, height: 400, border: '2px solid #fff'}} src={item.imagePath} /></li>)
                  })}
                </ul> */}
                  {carouselData &&
                    carouselData.map((item, index) => {
                      return (
                        <div key={index}>
                          <img
                            alt="carousel"
                            className={classes.carouselImage}
                            src={item.imagePath}
                          />
                        </div>
                      );
                    })}
                </div>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // data-aos="fade-up"
                // data-aos-duration="3000"
                className={classes.fingertipTitleGrid}
              >
                <Typography variant="h2" className={classes.fingertipTitle}>
                  Streamline your maintenance with the REDiFly Electronic Tech
                  Log
                </Typography>
              </Grid>
              <RediflyTabSection />
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // data-aos="fade-up"
                // data-aos-duration="3000"
                className={classes.fingertipTitle2Grid}
              >
                <Typography variant="h2" className={classes.fingertipTitle2}>
                  Scale your operations with REDiFly Flight Ops
                </Typography>
              </Grid>
              <WinopsTabSection />
              {/* <div class="ratio ratio-21x9">
              <iframe
                src="https://www.youtube.com/embed/_0tavthjNXw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div> */}
            </Grid>
            <FutureProofSection />
            <Grid className={classes.mainOurClientsGrid}>
              <Grid className={classes.clientsHeadingGrid}>
                <Typography variant="h2" className={classes.clientsHeading}>
                  Our Clients
                </Typography>
              </Grid>
              <LogoCarousel />
              {/* <div className={["ratio ratio-21x9", classes.sliderDiv].join(" ")}>
              <div>
                <SlickSlider />
              </div>
            </div> */}
            </Grid>
            <WebinarSection/>
            <TalktoUs />
            <DiscoverSection />
            {/* <AboutSection /> */}
          </div>
        </Layout>
      </div>
    );
  }
}

export default withStyles(styles)(IndexPage);
