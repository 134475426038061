import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import AboutPage from "./pages/AboutPage";
import CustomerPortal from "./pages/CustomerPortal";
import SupportPage from "./pages/SupportPage";
import IndexPage from "./pages/IndexPage";
import EtlMobileProducts from "./pages/products/EtlMobileProducts";
import NotFound from "./pages/NotFound";
import Redirect from "./pages/Redirect";
import { HelmetProvider } from "react-helmet-async";
import ETLPage from "./pages/ETLPage";
import OpsPage from "./pages/OpsPage";
import OpsMobileProducts from "./pages/products/OpsMobileProducts";
import "./assets/css/cookies.css";
import BlogPage from "./pages/BlogPage";
import ArticlePage from "./pages/ArticlePage";
import EtlWebProducts from "./pages/products/EtlWebProducts";
import OpsWebProducts from "./pages/products/OpsWebProducts";
import VideoPage from "./pages/VideoPage";
import ConfAppInfoPage from "./pages/ConfAppInfoPage";

function App() {
  useEffect(() => {
    const cookieBox = document.querySelector(".cookie-wrapper");
    const buttons = document.querySelectorAll(".cookie-button");

    if (document.cookie.includes("REDiFly")) return;
    if (cookieBox !== null && cookieBox !== undefined) {
      cookieBox.classList.add("show");

      buttons.forEach((button) => {
        button.addEventListener("click", () => {
          cookieBox.classList.remove("show");

          // if accepted
          if (button.id == "acceptBtn") {
            console.log("contains");
            //set cookies for 1 month. 60 = 1 min, 60 = 1 hours, 24  = 1 day, 30 = 30 days
            document.cookie =
              "cookieBy = REDiFly; max-age=" + 60 * 60 * 24 * 30;
          }
        });
      });
    }
  }, []);
  return (
    <Router>
      <div className="cookie-wrapper" id="cookieBox">
        <header>
          <i className="bx bx-cookie"></i>
          <h2>Cookies Consent</h2>
        </header>
        <div className="cookie-data">
          <p>
            We collect cookies to analyze our website traffic and performance;
            we never collect any personal data.{" "}
            <a href="https://www.termsfeed.com/privacy-policy/33c2e5b507ade1581ee04d399cb61802">
              {" "}
              Read more...
            </a>
          </p>
        </div>
        <div className="cookie-buttons">
          <button className="cookie-button" id="acceptBtn">
            Accept
          </button>
          <button className="cookie-button">Decline</button>
        </div>
      </div>
      <HelmetProvider>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/home" component={IndexPage} />
          <Route exact path="/etl" component={ETLPage} />
          <Route exact path="/ops" component={OpsPage} />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/blog" component={BlogPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/faqs" component={CustomerPortal} />
          <Route exact path="/videopage" component={VideoPage} />
          <Route
            exact
            path="/product/:productId"
            component={EtlMobileProducts}
          />
          <Route exact path="/wproduct/:productId" component={EtlWebProducts} />
          <Route
            exact
            path="/opsproduct/:productId"
            component={OpsMobileProducts}
          />
          <Route
            exact
            path="/wopsproduct/:productId"
            component={OpsWebProducts}
          />
          <Route exact path="/winops" component={Redirect} />
          <Route exact path="/blog/:Name" component={ArticlePage} />
          <Route exact path="/apps" component={ConfAppInfoPage} />
          <Route component={NotFound} />
        </Switch>
      </HelmetProvider>
    </Router>
  );
}

export default App;
