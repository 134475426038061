import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import classes from '*.module.css';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FutureData from "../../data/futureProof.json";

const styles = (theme) => ({
  FutureProofSectionContainer: {
    background: "white",
    // background: 'rgba(140, 203, 176, 0.2)',
    marginTop: "0.1em",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "1em",
    },
  },
  futureProofItem: {
    display: "flex",
    margin: "auto",
    maxWidth: "15rem",
    height: "15.5rem",
    backgroundColor: "#fefbfb",
    borderRadius: "1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.4em",
    [theme.breakpoints.down("xs")]: {
      // padding: "1em 0",
      marginBottom: "1rem",
      alignItems: "left",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // padding: "1em 0",
      marginBottom: "1rem",
      alignItems: "left",
      padding: "2em",
    },
    [theme.breakpoints.up("md")]: {
      // padding: "1em 0",
      marginBottom: "2rem",
      alignItems: "left",
    },
  },
  futureProofItemImage: {
    marginTop: "5px",
    width: 40, // width: 81,
    height: 40, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  futureProofItemTitle: {
    marginTop: 0,
    fontWeight: 500,
    // fontSize: 16,
    fontSize: 28, // fontSize: 37,
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  futureProofTitleDescItem: {
    marginTop: "1em",
    // fontSize: '0.9rem',
    fontSize: 20, // fontSize: 25,
    letterSpacing: 0,
    width: "100%",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 20,
    },
  },
  futureProofFloatingImg: {
    position: "static",
    height: 400,
    width: 600,
    bottom: "60%",
    right: "10%",
    // border: '2px solid #7d869080',
    [theme.breakpoints.down("xs")]: {
      left: 0,
      bottom: 0,
      height: "auto",
      width: "auto",
      position: "static",
      margin: "1em 0",
    },
    [theme.breakpoints.between("sm", "md")]: {
      left: 0,
      bottom: 0,
      height: "auto",
      position: "static",
      margin: "1em 0",
    },
    [theme.breakpoints.between("md")]: {
      position: "relative",
      height: 400,
      width: 600,
      bottom: "50%",
      left: "5%",
    },
  },
  futureProofFloatingImgContainer: {
    display: "flex",
    justifyContent: "right",
    paddingBottom: "2rem",
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      margin: "1em 0",
    },
  },
  FutureProofSubSectionContainer: {
    padding: "2em",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  futureProofTitle: {
    marginTop: 10,
    fontWeight: 100,
    fontFamily: "AvenirLTStd-Roman",
    width: "90%",
    fontSize: 35, //fontSize: 61,
    color: "#223140",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  futureProofTitleDesc: {
    marginTop: "1em",
    // fontSize: '0.9rem',
    fontSize: 24, // fontSize: 26,
    letterSpacing: 0,
    width: "100%",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 25,
    },
  },
  futureItemGridContainer: {
    margin: "auto",
    paddingTop: "3rem",
    [theme.breakpoints.between("xs", "sm")]: {
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'yellow'
    },
  },
  sectionContainer: {
    marginTop: "4em",
  },
  FutureProofSubSectionContainerTitleDesc: {
    paddingLeft: "1em",
  },
  mainFutureDescGrid: {
    paddingTop: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  futureDescGap: {
    padding: "1rem",
    backgroundColor: "#f0f8ff",
    borderRadius: "1rem",
    width: "50%",
  },
  bookButtonGrid: {
    paddingTop: "0rem",
  },
  futureDescStyle: {
    display: "flex",
    fontFamily: "AvenirLTStd-Book",
    justifyContent: "center",
    fontSize: 15,
  },
  futureDescStyle2: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "AvenirLTStd-Book",
    fontSize: 15,
  },
  bookDemoButton: {
    background: "#B41E1C",
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: "AvenirLTStd-Roman",
    marginTop: "2em",
    fontWeight: "none",
    fontSize: 15, //fontSize: 30,
    borderRadius: 5,
    width: 150,
    [theme.breakpoints.down("xs")]: {
      width: 170,
      fontSize: "0.9rem",
      marginLeft: 0,
    },
  },
});

class FutureProofSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }

  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Demo Request");
    const email = "sales@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={[
          classes.FutureProofSectionContainer,
          "future-proof-section-footer",
        ].join(" ")}
        // data-aos="fade-down"
        // data-aos-easing="ease-in-sine"
      >
        <Grid container className={classes.FutureProofSubSectionContainer}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            //data-aos="zoom-in-up"
            className={classes.FutureProofSubSectionContainerTitleDesc}
          >
            <Typography variant="h4" className={classes.futureProofTitle}>
              {FutureData.title}
            </Typography>
            <Typography
              variant="body1"
              className={classes.futureProofTitleDesc}
            >
              {FutureData.desc}
            </Typography>
          </Grid>
          {/* <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.futureProofFloatingImgContainer}
            data-aos="zoom-in"
          >
            <img
              alt="aircraft technician in yellow vest carrying out an inspection"
              src={FutureData.floatingImg}
              className={classes.futureProofFloatingImg}
            />
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container className={classes.futureItemGridContainer}>
              {FutureData &&
                FutureData.data &&
                FutureData.data.map((item, index) => {
                  return (
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={12}
                      className={classes.futureProofItem}
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            alt="aircraft technician in yellow vest carrying out an inspection"
                            src={item.imagePath}
                            className={classes.futureProofItemImage}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            variant="h6"
                            className={classes.futureProofItemTitle}
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body2"
                        className={classes.futureProofTitleDescItem}
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid className={classes.mainFutureDescGrid}>
              <Grid className={classes.futureDescGap}>
                <Typography className={classes.futureDescStyle}>
                  Get a walkthrough of our ETL’s capabilities and learn how it
                  can boost your operational
                </Typography>
                <Typography className={classes.futureDescStyle2}>
                  efficiency with a FREE TRIAL available upon request.
                </Typography>
              </Grid>
              <Grid className={classes.bookButtonGrid}>
                <Button
                  variant="contained"
                  onClick={this.handleEmailClick}
                  color="primary"
                  className={classes.bookDemoButton}
                >
                  Book a Demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(FutureProofSection);
