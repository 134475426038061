import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../layouts/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AircraftBackground from "../assets/images/main-homebackground-aircraft-image.png";
import DiscoverSection from "../components/pagesComponents/DiscoverSection";
import Banner from "../components/pagesComponents/Banner";
import BlogTile from "../components/pagesComponents/BlogTile";
import ETLBrochure from "../assets/pdfs/REDiFly eTechlog Brochure - 2023.pdf";
import axios from "axios";
import {
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const styles = (theme) => ({
  carouselBlock: {
    height: "80vh",
    padding: "2em 0 0 5em",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: "1em 0",
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "2.5em 0 0 0",
    },
  },
  carouselImage: {
    background: `url(${AircraftBackground}) -60% 100% no-repeat padding-box`,
    backgroundSize: "contain",
    width: 400,
    height: 500,
    maxWidth: "initial",
    [theme.breakpoints.down("xs")]: {
      width: 500,
      height: 500,
    },
  },
  carouselContainerLists: {
    height: 600,
    overflow: "hidden",
    position: "relative",
    right: "8%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      margin: "auto",
      right: "5%",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
  homePageTitle: {
    textAlign: "left",
    letterSpacing: "0.66px",
    color: "#223140",
    padding: "3em 0.5em 0 1em",
    width: "90%",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 0,
      padding: 0,
      textAlign: "center",
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0.5em 0.5em 0 1em",
      width: "100%",
      marginTop: "10%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
    },
  },
  homePageTitledesc: {
    padding: "2em 1em 1em 1.75em",
    fontSize: 20,
    fontWeight: 100,
    width: "90%",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      marginTop: "1em",
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "2em 1em 1em 1em",
      fontSize: 25,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  buttonStyles: {
    textTransform: "inherit",
    width: 400,
    fontSize: 20,
  },
  homeButtonItem: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 1em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
  exploreButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    marginLeft: "1.5em",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20,
    },
  },
  demoButtonGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  demoButtonStyle: {
    textTransform: "inherit",
    width: 200,
    fontSize: 20,
    fontFamily: "AvenirLTStd-Roman",
    background: "#fff",
    color: "#B41E1C",
    border: "1px solid #B41E1C",
    fontWeight: "none",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
      margin: "1em 0 0 0em",
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 200,
      margin: "2em 0 0 0em",
    },
  },
  backgroundDesign1: {
    padding: "0.4em",
  },
  buttonContainer: {
    marginTop: "1em",
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.between("sm", "md")]: {},
  },
  indexCarousrlBlockContainer: {
    marginTop: "0em",
    padding: "-1px 0 0 0em",
    paddingBottom: "0.1rem",
  },
});

const baseUrl = "https://cms.redifly.com/api/data";
const GetBlogData = async () => {
  try {
    const response = await axios.get(`${baseUrl}/blog-pages`);
    return response.data;
  } catch (error) {
    console.error("Error fetching blog pages:", error);
  }
};
class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: false,
      blogData: [],
      filteredBlogData: [],
      currentPage: 1,
      itemsPerPage: 6,
      dateValue: null,
      categories: [],
      selectedCategory: "",
    };
  }

  loadBlogData = async () => {
    try {
      const data = await GetBlogData();
      const categories = [...new Set(data.map((blog) => blog.Category))];
      this.setState({ blogData: data, categories, filteredBlogData: data });
    } catch (error) {
      console.error("Error loading blog data:", error);
    }
  };

  handleChangePage = (event, value) => {
    this.setState({ currentPage: value });
  };

  handleDateChange = (newValue) => {
    this.setState({ dateValue: newValue });
  };

  handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    const filteredBlogData = selectedCategory
      ? this.state.blogData.filter((blog) => blog.Category === selectedCategory)
      : this.state.blogData;

    this.setState({ selectedCategory, filteredBlogData, currentPage: 1 });
  };

  componentDidMount() {
    this.loadCarousel();
    this.loadBlogData();
  }

  loadCarousel = () => {
    window.jQuery(".my-flipster").slick({
      autoplay: true,
      autoplaySpeed: 10000,
      infinite: true,
      vertical: true,
      verticalSwiping: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  };

  handleDownloadClick = () => {
    const pdfFile = ETLBrochure;
    const anchor = document.createElement("a");
    anchor.href = pdfFile;
    anchor.download = "REDiFly eTechlog Brochure - 2023";
    anchor.click();
  };

  handleEmailClick = () => {
    const subject = encodeURIComponent("REDiFly Product Demo Request");
    const email = "sales@redifly.com";
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  render() {
    const { classes } = this.props;
    const {
      currentPage,
      itemsPerPage,
      value,
      filteredBlogData,
      dateValue,
      categories,
      selectedCategory,
    } = this.state;
    const totalPages = Math.ceil(filteredBlogData.length / itemsPerPage);
    const paginatedData = filteredBlogData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return (
      <div>
        <Layout>
          <div>
            <Banner />
            <Grid container className={classes.indexCarousrlBlockContainer}>
              <Grid
                item
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes.backgroundDesign1}
              >
                <Typography variant="h1" className={classes.homePageTitle}>
                  The latest news, articles and industry insights
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.homePageTitledesc}
                >
                  We provide bespoke Electronic Tech Log and Flight Operations
                  solutions to enable operators to become fully connected and
                  data driven providers of aviation services.
                </Typography>
                <Grid container className={classes.buttonContainer}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={10}
                    xs={12}
                    className={classes.demoButtonGrid}
                  >
                    <Button
                      variant="contained"
                      onClick={this.handleEmailClick}
                      className={classes.demoButtonStyle}
                    >
                      Book a Demo
                    </Button>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className={classes.homeButtonItem}
                  >
                    <Button
                      variant="contained"
                      onClick={this.handleDownloadClick}
                      target="_blank"
                      className={[
                        classes.buttonStyles,
                        classes.exploreButtonStyle,
                      ].join(" ")}
                    >
                      Download Our Brochure
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "1em",
              }}
            >
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={this.handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <BlogTile blogData={paginatedData} />
            <Pagination
              color="secondary"
              className={classes.pagination}
              count={totalPages}
              page={currentPage}
              onChange={this.handleChangePage}
            />
            <DiscoverSection />
          </div>
        </Layout>
      </div>
    );
  }
}

export default withStyles(styles)(BlogPage);
