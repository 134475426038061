import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@material-ui/core/Typography";
import Checkmark from "../../assets/images/etl-page/CheckMark-ETL.png";
import IFAircraft from "../../assets/images/etl-page/IFAircraft.png";
import AircraftBackground from "../../assets/images/main-homebackground-aircraft-image.png";

const styles = (theme) => ({
  mainGrid: {
    paddingTop: "3em",
    paddingBottom: "5em",
  },
  mainHeadingGrid: {
    paddingLeft: "3em",
    paddingBottom: "3em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0.5em",
    },
  },
  mainHeading: {
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 40,
    },
  },
  mainOperatorContent: {
    display: "flex",
  },
  operatorContentGrid: {
    padding: "2em 0 0 7em",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      padding: "0 0 0 0em",
      justifyContent: "center",
    },
  },
  leftOperatorsGrid: {
    paddingRight: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      paddingLeft: "2rem",
    },
  },
  rightOperatorsGrid: {
    paddingRight: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      paddingTop: "2rem",
      paddingLeft: "2rem",
    },
  },
  operatorTypeGridStyle: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f0fff0",
    borderRadius: "5px",
    paddingLeft: "0.5rem",
    paddingBottom: "0.15em",
  },
  checkmarkGridStyle: {},
  checkMarkImage: {
    width: 20, // width: 81,
    height: 20, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  operatorTypeTextGrid: {
    marginTop: "3px",
  },
  checkMarkTextStyle: {
    whiteSpace: "nowrap",
    fontFamily: "AvenirLTStd-Roman",
    color: "green",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  operatorImageGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {},
  },
  operatorImage: {
    background: `url(${AircraftBackground}) -60% 100% no-repeat padding-box`,
    backgroundSize: "contain",
    width: 300, // width: 81,
    height: 300, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 200,
      height: 200,
      marginTop: "2em",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 280,
      height: 280,
      marginTop: "0em",
    },
  },
});

export class AirOperators extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid className={classes.mainGrid}>
          <Grid className={classes.mainHeadingGrid}>
            <Typography variant="h2" className={classes.mainHeading}>
              Tailored to air operators of any size
            </Typography>
          </Grid>
          <Grid container className={classes.mainOperatorContent}>
            <Grid item xs={6} className={classes.operatorContentGrid}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.leftOperatorsGrid}
                >
                  <Stack spacing={5}>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        lg={3}
                        className={classes.checkmarkGridStyle}
                      >
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        lg={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          Business
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid item xs={3} className={classes.checkmarkGridStyle}>
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          Private Charter
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid item xs={3} className={classes.checkmarkGridStyle}>
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          SPO
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.rightOperatorsGrid}
                >
                  <Stack spacing={5}>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        lg={3}
                        className={classes.checkmarkGridStyle}
                      >
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        md={9}
                        lg={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          Commercial
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        lg={3}
                        className={classes.checkmarkGridStyle}
                      >
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        md={9}
                        lg={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          Cargo
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.operatorTypeGridStyle}>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        lg={3}
                        className={classes.checkmarkGridStyle}
                      >
                        <img
                          alt="checkmark"
                          src={Checkmark}
                          className={classes.checkMarkImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        md={9}
                        lg={9}
                        className={classes.operatorTypeTextGrid}
                      >
                        <Typography className={classes.checkMarkTextStyle}>
                          Rotorcraft
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={classes.operatorImageGrid}
            >
              <img
                alt="checkmark"
                src={IFAircraft}
                className={classes.operatorImage}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AirOperators);
