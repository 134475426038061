import React from "react";
import { Box, Container, Typography } from "@mui/material";

const VideoPage = () => {
  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" component="div" align="center">
          Enabling Paperless Operations with the REDiFly eTechlog
        </Typography>
      </Box>
      <Box mt={2}>
        <iframe
          src="https://player.vimeo.com/video/903258403?h=b59610754d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="1280"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Updated Full Webinar Video"
        ></iframe>
      </Box>
    </Container>
  );
};

export default VideoPage;
