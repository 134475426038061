import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  FutureProofSectionContainer: {
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "2em",
    },
  },
  futureProofTitleDescGrid: {
    display: "flex",
    justifyContent: "center",
  },
  futureProofTitleDesc: {
    // fontSize: '0.9rem',
    width: "52%",
    fontSize: 18, // fontSize: 26,
    marginTop: "1em",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "1rem",
    },
  },
  futureProofFloatingImg: {
    position: "relative",
    height: 300,
    bottom: "30%",
    left: "5%",
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      left: 0,
      bottom: 0,
      height: "auto",
      position: "static",
      margin: "1em 0",
    },
    [theme.breakpoints.between("sm", "md")]: {
      left: 0,
      bottom: 0,
      height: "auto",
      position: "static",
      margin: "1em 0",
    },
    [theme.breakpoints.between("md", "lg")]: {
      position: "relative",
      height: 300,
      bottom: "30%",
      left: "5%",
    },
  },
  futureProofFloatingImgContainer: {
    [theme.breakpoints.between("sm", "md")]: {
      margin: "1em 0",
    },
  },
  FutureProofSubSectionContainer: {
    backgroundColor: "#fafcff",
    padding: "2em 2em 2em 3em",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "2em 1em",
    },
  },
  futureProofTitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    fontWeight: 100,
    fontSize: 30, // fontSize: 61,
    fontFamily: "AvenirLTStd-Roman",
    width: "100%",
    color: "#223140",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  futureItemGridContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      // background: 'green'
    },
    [theme.breakpoints.between("sm", "md")]: {
      // background: 'red'
    },
    [theme.breakpoints.between("md", "lg")]: {
      // background: 'yellow'
    },
  },
  sectionContainer: {
    marginTop: "4em",
  },
  futureItemGridContainerBlock: {
    margin: "4em 0",
  },
  futureProofItem: {
    display: "contain",
    margin: "auto",
    maxWidth: "auto",
    height: "auto",
    backgroundColor: "white",
    borderRadius: "1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.4em",
    [theme.breakpoints.down("xs")]: {
      // padding: "1em 0",
      marginBottom: "1rem",
      alignItems: "left",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "1em 0",
      marginBottom: "2rem",
      alignItems: "left",
    },
  },
  futureProofItemImage: {
    marginTop: "5px",
    width: 45, // width: 81,
    height: 45, // height: 91,
    [theme.breakpoints.down("xs")]: {
      width: 25,
      height: 25,
    },
  },
  futureProofItemTitle: {
    marginTop: 0,
    fontWeight: 500,
    // fontSize: 16,
    fontSize: 28, // fontSize: 37,
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
  },
  futureProofTitleDescItem: {
    marginTop: "1em",
    // fontSize: '0.9rem',
    fontSize: 19, // fontSize: 25,
    letterSpacing: 0,
    width: "100%",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 20,
    },
  },
  mainFutureDescGrid: {
    paddingTop: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  futureDescGap: {
    padding: "1rem",
    backgroundColor: "# ",
    borderRadius: "1rem",
    width: "60%",
  },
  futureDescStyle: {
    display: "flex",
    fontFamily: "AvenirLTStd-Book",
    justifyContent: "center",
    fontSize: 15,
  },
  futureDescStyle2: {
    display: "flex",
    fontFamily: "AvenirLTStd-Book",
    justifyContent: "center",
    fontSize: 15,
  },
  bookButtonGrid: {
    paddingTop: "0rem",
  },
  bookDemoButton: {
    background: "#B41E1C",
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: "AvenirLTStd-Roman",
    marginTop: "2em",
    fontWeight: "none",
    fontSize: 15, //fontSize: 30,
    borderRadius: 5,
    width: 150,
    [theme.breakpoints.down("xs")]: {
      width: 170,
      fontSize: "0.9rem",
      marginLeft: 0,
    },
  },
  futureProofTitleDescWidth: {
    paddingTop: "0.8em",
  },
  futureProofTitleDescStyle: {
    display: "flex",
    fontFamily: "AvenirLTStd-Book",
    justifyContent: "center",
    fontSize: 18,
    color: "grey",
  },
});

class ProductBenifitsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // setupHeaderBg();
  }

  render() {
    const { classes, productBenefits } = this.props;
    return (
      <div
        className={[
          classes.FutureProofSectionContainer,
          "future-proof-section-footer",
        ].join(" ")}
      >
        <Grid container className={classes.FutureProofSubSectionContainer}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* //data-aos="fade-up" */}
            <Typography variant="h4" className={classes.futureProofTitle}>
              {productBenefits && productBenefits.title}
            </Typography>
            <Grid className={classes.futureProofTitleDescGrid}>
              <Grid className={classes.futureProofTitleDescWidth}>
                <Typography className={classes.futureProofTitleDescStyle}>
                  Our flexible logbook solution enables fully connected, data
                  driven
                </Typography>
                <Typography className={classes.futureProofTitleDescStyle}>
                  and efficient air transport services.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.futureItemGridContainerBlock}
          >
            <Grid container className={classes.futureItemGridContainer}>
              {productBenefits &&
                productBenefits.benifitsData &&
                productBenefits.benifitsData.map((item, index) => {
                  return (
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={12}
                      className={classes.futureProofItem}
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            alt="aircraft technician in yellow vest carrying out an inspection"
                            src={item.icon}
                            className={classes.futureProofItemImage}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            variant="h6"
                            className={classes.futureProofItemTitle}
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body2"
                        className={classes.futureProofTitleDescItem}
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid className={classes.mainFutureDescGrid}>
              <Grid className={classes.futureDescGap}>
                <Typography className={classes.futureDescStyle}>
                  Get a walkthrough of our ETL’s capabilities and learn how it
                  can boost your operational
                </Typography>
                <Typography className={classes.futureDescStyle2}>
                  efficiency with a FREE TRIAL available upon request.
                </Typography>
              </Grid>
              <Grid className={classes.bookButtonGrid}>
                <Button
                  variant="contained"
                  href="/about#contact"
                  color="primary"
                  className={classes.bookDemoButton}
                >
                  Book a Demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ProductBenifitsSection);
