import React, { Component } from "react";
import Layout from "../../layouts/index";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import etlProductData from "../../data/etlMobileProducts.json";
import ProductBenefitsSection from "../../components/pagesComponents/ProductBenefitsSection";
import { Helmet } from "react-helmet-async";
import TalktoUs from "../../components/pagesComponents/TalktoUs";
import DiscoverSection from "../../components/pagesComponents/DiscoverSection";
import ETLBrochure from "../../assets/pdfs/REDiFly eTechlog Brochure - 2023.pdf";

const styles = (theme) => ({
  videoMainGrid: {
    height: "72vh",
    margin: "0em 0 0 0",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
      background: "transparent",
    },
    "@media (min-width: 760px)": {
      height: "auto",
      background: "transparent",
      margin: "4em 0 0 0",
    },
    "@media (min-width: 1200px)": {
      margin: "6.7em 0 0 0",
    },
  },
  mainCenterGrid: {
    display: "flex",
    justifyContent: "center",
  },
  videoItemsMainGrid: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 0 0",
      width: "100%",
    },
    [theme.breakpoints.down("sm", "md")]: {
      padding: "2em 0 0 0",
      width: "100%",
    },
  },
  carouselBlock: {
    // background: 'transparent linear-gradient(180deg, #0278EF 0%, #FFFFFFE1 86%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
    // height: '100vh',
    padding: "4em 0",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: 0,
    },
  },
  produtGradientImage: {
    // background: `transparent url(${gradientImagebackground}) 50% 0% no-repeat padding-box`,
    // backgroundSize: 'contain',
    // backgroundSize: '100% 100%',
    [theme.breakpoints.down("xs")]: {
      background: "transparent !important",
    },
  },
  carouselBlockBackgroundImg: {
    // position: 'relative',
    // right: '20%',
    // height: 500,
    // width: 500,
    // bottom: '10%',
    // [theme.breakpoints.down('xs')]: {
    //   right: 0,
    // }
    maxWidth: "initial",
    position: "relative",
    height: 600,
    bottom: "10%",
    [theme.breakpoints.down("xs")]: {
      right: 0,
      height: 500,
      width: 500,
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm", "md")]: {
      margin: "4em 0 0 0",
      height: 500,
      width: 500,
    },
  },
  productImgPilotTechCrew: {
    right: "25%",
    width: "125%",
    [theme.breakpoints.down("xs")]: {
      right: 0,
      height: 400,
      width: 400,
      maxWidth: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 400,
      height: 400,
    },
  },
  productImgCommand: {
    right: "40%",
    width: 600,
    height: 450,
    [theme.breakpoints.down("xs")]: {
      right: 0,
      height: 500,
      width: 500,
      maxWidth: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 300,
      width: 500,
    },
  },
  productImgCore: {
    right: "50%",
    width: 950,
    [theme.breakpoints.down("xs")]: {
      right: 0,
      height: 500,
      width: 500,
      maxWidth: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 300,
      width: 500,
    },
  },
  buttonStyles: {
    textTransform: "inherit",
    width: 350, // width: 200,
    fontSize: 20, // fontSize: 30,
    borderRadius: "8px",
    // fontSize: '0.9rem',
  },
  exploreButtonStyle: {
    whiteSpace: "nowrap",
    background: "#B41E1C",
    color: "#fff",
    fontWeight: "none",
    // marginLeft: "1.5em",
    fontFamily: "AvenirLTStd-Roman",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 250,
      marginLeft: 0,
      fontSize: 15,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 350,
      fontSize: 20,
      // width: '100%',
    },
    [theme.breakpoints.up("lg", "xl")]: {
      fontSize: 20,
    },
  },
  demoButtonStyle: {
    background: "#B41E1C",
    color: "#fff",
    fontFamily: "S-Core-CoreSansA65Bold",
    fontWeight: "none",
    marginLeft: "1.3em",
    "&:hover": {
      background: "#fff",
      color: "#B41E1C",
      boxShadow: "5px 5px 13px 5px rgba(0,0,0,0.15)",
      border: "0px solid #fff",
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
      marginLeft: 0,
      fontSize: "0.9rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  productPageTitle: {
    color: "#223140",
    marginTop: "10%",
    padding: "0 0.7em 0 0",
    width: "85%",
    fontFamily: "AvenirLTStd-Roman",
    fontSize: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "4em 0 0 0",
      width: "100%",
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 35,
    },
  },
  productPageTitledesc: {
    padding: "2em 3.5em 2.5em 0em",
    fontSize: 20,
    fontWeight: 100,
    width: "85%",
    color: "#223140",
    fontFamily: "AvenirLTStd-Book",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "2em 1em 1em 0em",
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  productButtonContainer: {
    marginTop: "2em",
    marginBottom: "15em",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  productButtonItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 2em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
  productLinksSection: {
    // position: 'relative',
    // top: '40%',
    // right: '2em',
    // display: 'flex',
    // justifyContent: 'space-evenly',
    position: "relative",
    bottom: "100%",
    padding: "1em 0 1em 0.5em",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      padding: "0 0 1em 0",
      bottom: 0,
    },
  },
  linksListFirst: {
    paddingLeft: 0,
  },
  linksList: {
    fontFamily: "AvenirLTStd-Roman",
    color: "#00000054",
    textDecoration: "none",
    padding: "0 1em",
    // fontSize: '1.4rem',
    fontSize: 35, // fontSize: 40,
    // fontWeight: 'bold',
    [theme.breakpoints.down("xs")]: {
      padding: "10px 1em",
      background: "#0278ef",
      // background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
      margin: "10px 0",
      borderRadius: 10,
      color: "#fff",
      fontSize: "1rem",
      // fontWeight: 'bold',
    },
  },
  featuresBlock: {
    padding: "0em 0 2em 0",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "auto",
      paddingBottom: "4em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "0em 0 3em 0",
      height: "auto",
    },
  },
  featureBlockItemTitle: {
    // fontSize: '1.3rem',
    fontSize: 32, // fontSize: 34,
    fontWeight: "inherit",
    fontFamily: "AvenirLTStd-Roman",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "1.7rem",
      wordBreak: "break-word",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 35,
    },
  },
  featureBlockItemDesc: {
    fontFamily: "AvenirLTStd-Book",
    // fontSize: '0.9rem',
    fontSize: 22, // fontSize: 24,
    fontWeight: 100,
    padding: "30px 0px",
    textAlign: "none",
    width: "90%",
    color: "#000000c7",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 16,
      textAlign: "left",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 25,
    },
  },
  featureContainer: {
    padding: "0em 0em 0em 0em",
    [theme.breakpoints.down("xs")]: {
      padding: "0em 0em 0em 0em",
    },
    [theme.breakpoints.down("sm", "md")]: {
      padding: "0em 0em 0em 0em",
    },
  },
  productFeatureIcon: {
    width: "50px",
    height: "50px",
    [theme.breakpoints.down("xs")]: {
      width: "40px",
      height: "40px",
      
      display: "flex",
    },
  },
  alternateGridItemStyleDesc: {
    width: "100%",
  },
  fingertipTitleGrid: {
    width: "100%",
    paddingBottom: "1em",
  },
  fingertipTitle: {
    position: "relative",
    fontFamily: "AvenirLTStd-Roman",
    // top: '3em',
    fontSize: 35, // fontSize: 60,
    fontWeight: 100,
    // paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      top: 0,
      width: "100%",
      margin: 0,
      left: 0,
      textAlign: "center",
      fontSize: 22,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "45%",
      fontSize: 30,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      fontSize: 35,
    },
  },
  fingertipDesc: {
    position: "relative",
    fontFamily: "AvenirLTStd-Book",
    padding: "1em 6em 2em 0em",
    [theme.breakpoints.down("xs")]: {
      left: "2em",
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%",
      textAlign: "left",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "50%",
    },
  },
  featureTextStyle: {
    padding: "4em 0 0 0",
  },
  featureImageStyle: {
    display: "flex",
    justifyContent: "center",
    padding: "4em 0 0 1em",
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 0 0",
    },
    // [theme.breakpoints.between("sm", "md")]: {
    //   paddingLeft: "5em",
    // },
  },
  featureImage: {
    width: "60%",
    borderRadius: "10px",
    // [theme.breakpoints.between("xl")]: {
    //   width: "50%",
    //   height: "30%",
    //   borderRadius: "10px",
    // },
  },
  bigScreenProducts: {
    "@media (min-width: 300px)": {
      display: "flex",
    },
    "@media (min-width: 1200px)": {
      display: "flex",
    },
  },
  brochureButtonGrid: {
    display: "flex",
    padding: "2em 0 2em 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1em 0 1em 0",
      justifyContent: "center",
      display: "flex",
    },
  },
});

class EtlMobileProducts extends Component {
  constructor(props) {
    // console.log(props)
    super(props);
    this.state = {
      productDataAll: etlProductData,
      etlProductData: null,
      productId:
        props &&
        props.match &&
        props.match.params &&
        props.match.params.productId,
    };
  }
  componentDidMount() {
    const data = [...etlProductData];
    const productId =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.productId;
    this.setState({
      etlProductData: data.find((item) => item.id === productId),
    });
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params !== nextProps.match.params) {
      const data = [...etlProductData];
      const productId =
        nextProps &&
        nextProps.match &&
        nextProps.match.params &&
        nextProps.match.params.productId;
      this.setState(
        {
          etlProductData: data.find((item) => item.id === productId),
          productId: productId,
        },
        () => window.scrollTo(0, 0)
      );
    }
    // console.log('props.match.params', nextProps.match.params)
  }

  aboutLinkActiuve = (evt) => {
    let links = document.querySelectorAll(".abt-links");
    let arrayLinks = Array.from(links);
    arrayLinks.map((item) => {
      item.classList.remove("abt-link-active");
    });
    evt.currentTarget.classList.add("abt-link-active");
  };

  handleDownloadClick() {
    const pdfFile = ETLBrochure;
    const anchor = document.createElement("a");
    anchor.href = pdfFile;
    anchor.download = "REDiFly eTechlog Brochure - 2023";
    anchor.click();
  }

  render() {
    const { classes } = this.props;
    const { etlProductData } = this.state;
    let className = null;
    if (etlProductData && etlProductData.id) {
      if (
        etlProductData.id === "pilot" ||
        etlProductData.id === "tech" ||
        etlProductData.id === "crew"
      ) {
        className = classes.productImgPilotTechCrew;
      } else if (etlProductData.id === "command") {
        className = classes.productImgCommand;
      } else if (etlProductData.id === "core") {
        className = classes.productImgCore;
      }
    }

    return (
      <Layout>
        <Helmet>
          <title>REDiFly Products</title>
          <meta
            name="description"
            contents="Information about wide variety of our products which includes REDiFly ETL, REDiFly Ground and REDiFly Flight Ops."
          />
          <link rel="canonical" href="/product" />
        </Helmet>
        <div className="container">
          <Grid container style={{ marginTop: "2em" }}>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              {/* data-aos="zoom-in" */}
              <Typography variant="h4" className={classes.productPageTitle}>
                {" "}
                REDiFly {etlProductData && etlProductData.name}{" "}
              </Typography>
              <Typography
                variant="body2"
                className={classes.productPageTitledesc}
                dangerouslySetInnerHTML={{
                  __html: etlProductData && etlProductData.description,
                }}
              />
              <Grid container className={classes.productButtonContainer}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.productButtonItem}
                >
                  <Button
                    variant="contained"
                    href="https://calendly.com/redifly/discoverycall"
                    target="_blank"
                    className={[
                      classes.buttonStyles,
                      classes.exploreButtonStyle,
                    ].join(" ")}
                  >
                    Schedule a Discovery Call
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className={[
                classes.carouselBlock,
                classes.produtGradientImage,
              ].join(" ")}
              style={{
                background: etlProductData && etlProductData.background1,
                backgroundSize: "100%",
                borderRadius: "20px",
              }}
              data-aos="zoom-in-down"
            >
              <img
                alt="product"
                className={[classes.carouselBlockBackgroundImg, className].join(
                  " "
                )}
                src={etlProductData && etlProductData.imagePath}
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // data-aos="fade-up"
              // data-aos-duration="3000"
              className={classes.fingertipTitleGrid}
            >
              <Typography variant="h2" className={classes.fingertipTitle}>
                {etlProductData && etlProductData.subheading}
              </Typography>
              <Typography variant="body2" className={classes.fingertipDesc}>
                {etlProductData && etlProductData.subdescription}
              </Typography>
            </Grid>
            {/* <Grid item lg={12} md={6} sm={6} xs={12}>
              <Typography variant="h5" className={classes.productLinksSection}>
                <a
                  className={[
                    classes.linksList,
                    "abt-links",
                    classes.linksListFirst,
                  ].join(" ")}
                  href="#feture"
                  onClick={this.aboutLinkActiuve.bind(this)}
                >
                  Features
                </a>
                <a
                  className={[classes.linksList, "abt-links"].join(" ")}
                  href="#benefits"
                  onClick={this.aboutLinkActiuve.bind(this)}
                >
                  Benefits
                </a>
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            container
            className={classes.featureContainer}
            id="feature"
            style={{
              background:
                etlProductData &&
                etlProductData.productFeaturBackgroundGradients,
            }}
          >
            {etlProductData &&
              etlProductData.productFeature &&
              etlProductData.productFeature.map((item, index) => {
                return (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                    className={classes.featuresBlock}
                  >
                    <Grid container className={classes.bigScreenProducts}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={7}
                        xl={7}
                        className={classes.featureTextStyle}
                      >
                        <Grid container>
                          <Grid item xs={1}>
                            <img
                              alt="feature"
                              src={item.icon}
                              className={classes.productFeatureIcon}
                            />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography
                              variant="h6"
                              className={classes.featureBlockItemTitle}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.featureBlockItemDesc}
                            >
                              {item.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={5}
                        xl={5}
                        className={classes.featureImageStyle}
                      >
                        <img
                          alt="Feature Image"
                          src={item.featureimage}
                          className={classes.featureImage}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Grid className={classes.mainCenterGrid}>
            <div className={classes.videoItemsMainGrid}>
              <div class="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/3tOQcehSfAM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ borderRadius: "20px" }}
                ></iframe>
              </div>
            </div>
          </Grid>
          <Grid className={classes.mainCenterGrid}>
            <Grid className={classes.brochureButtonGrid}>
              <Button
                variant="contained"
                onClick={this.handleDownloadClick}
                className={[
                  classes.buttonStyles,
                  classes.exploreButtonStyle,
                ].join(" ")}
              >
                Download Our Brochure
              </Button>
            </Grid>
          </Grid>
          <div id="benefits">
            <ProductBenefitsSection
              productBenefits={etlProductData && etlProductData.productBenefits}
            />
          </div>
          <TalktoUs />
          <DiscoverSection />
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(EtlMobileProducts);
